<template>
  <div class="brand-fetcher">
    <div class="input-container">
      <input v-model="brandDomain" type="text" :placeholder="$t('brandFetcher.enterCompanyEmail')"
        @click="handleInputClick" @keydown.enter="handleFetchBrandData" :class="{ pulsating: isFirstVisit }" />
    </div>
    <div class="button-container">
      <button class="action-button" v-if="!fetched" @click="handleFetchBrandData" :disabled="!brandDomain">
        {{ $t('brandFetcher.syncWithBrandData') }}
      </button>
      <button class="action-button" v-else @click="handleRollBrandData" :disabled="!brandDomain">
        {{ $t('brandFetcher.changeColors') }}
      </button>
    </div>
    <div v-if="errorMessage" class="brand-error-toast">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BACKEND_URL } from "@/environments";

export default {
  name: "BrandFetcher",
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      brandDomain: "",
      brandData: null,
      fetched: false,
      lastFetchedDomain: "",
      isFirstVisit: !localStorage.getItem("brandFetcherInputClicked"),
      errorMessage: "",
    };
  },
  computed: {
    copyLinkUrl() {
      let domain = this.brandDomain;
      if (domain.includes("@")) {
        domain = domain.split("@")[1];
      }
      return `https://generator.gsignature.com/?domain=${domain}`;
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const domainFromUrl = urlParams.get("domain");
    if (domainFromUrl) {
      this.brandDomain = domainFromUrl;
      this.handleFetchBrandData();
    }
  },
  watch: {
    brandDomain(newVal) {
      if (newVal !== this.lastFetchedDomain) {
        this.fetched = false;
      }
    },
  },
  methods: {
    handleInputClick() {
      if (this.isFirstVisit) {
        this.isFirstVisit = false;
        localStorage.setItem("brandFetcherInputClicked", "true");
      }
    },
    async handleFetchBrandData() {
      if (!this.brandDomain) return;
      if (!this.brandDomain.includes("@")) {
        this.errorMessage = "Invalid email format";
        return;
      }
      const email = this.brandDomain;
      const domainPart = email.split("@")[1].toLowerCase();
      const disallowedDomains = [
        "gmail.com",
        "yahoo.com",
        "outlook.com",
        "hotmail.com",
        "live.com",
        "aol.com",
        "icloud.com",
        "msn.com",
      ];
      const skipFetch = disallowedDomains.includes(domainPart);
      if (skipFetch) {
        this.errorMessage = "Using non-company email";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      } else {
        this.errorMessage = "";
      }
      await this.fetchBrandData(skipFetch);
    },
    async fetchBrandData(skipFetch = false) {
      try {
        const email = this.brandDomain;
        const domainForCache = email.includes('@') ? email.split('@')[1] : email;
        const cacheKey = `brandCache_${domainForCache}`;
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          this.brandData = JSON.parse(cachedData);
          if (!skipFetch) {
            this.fetched = true;
          }
        } else {
          const lang = this.$i18n.locale;
          const url = `${BACKEND_URL}/brand-data?email=${encodeURIComponent(email)}&lang=${encodeURIComponent(lang)}${skipFetch ? "&skipFetch=true" : ""}`;
          const response = await axios.get(url);
          this.brandData = response.data;
          localStorage.setItem(cacheKey, JSON.stringify(this.brandData));
          if (!skipFetch) {
            this.fetched = true;
          }
        }
        this.lastFetchedDomain = this.brandDomain;
        if (!skipFetch) {
          sessionStorage.setItem("brandEmail", this.brandDomain);
        }
        this.rollBrandData();
      } catch (error) {
        console.error("Error fetching brand data:", error);
      }
    },
    handleRollBrandData() {
      if (!this.brandDomain) return;
      this.rollBrandData();
    },
    rollBrandData() {
      if (!this.brandData) return;

      const brandColors = Array.isArray(this.brandData.colors) ? this.brandData.colors : [];
      const brandLogos = Array.isArray(this.brandData.logos) ? this.brandData.logos : [];
      const brandImages = Array.isArray(this.brandData.images) ? this.brandData.images : [];
      const brandLinks = Array.isArray(this.brandData.links) ? this.brandData.links : [];

      const colorSet = this.pickCohesiveColors(brandColors);
      const updates = {};
      if (colorSet) {
        Object.assign(updates, colorSet);
      }

      if (brandLogos.length > 0) {
        const randomLogoIdx = Math.floor(Math.random() * brandLogos.length);
        const randomLogoObj = brandLogos[randomLogoIdx];
        if (Array.isArray(randomLogoObj.formats) && randomLogoObj.formats.length > 0) {
          updates.logoImage = randomLogoObj.formats[0].src;
        }
      }

      const bannerArray = brandImages.filter((img) => img.type === "banner");
      if (bannerArray.length > 0) {
        const bannerIdx = Math.floor(Math.random() * bannerArray.length);
        const bannerObj = bannerArray[bannerIdx];
        if (Array.isArray(bannerObj.formats) && bannerObj.formats.length > 0) {
          updates.banner = { ...this.formData.banner, url: bannerObj.formats[0].src };
        }
      }

      if (this.brandData.name) {
        updates.companyName = this.brandData.name;
      }
      if (this.brandData.location && this.brandData.location.city) {
        updates.companyAddress = this.brandData.location.city;
      }
      if (this.brandData.description) {
        updates.disclaimer = this.brandData.description;
      }

      if (brandLinks.length > 0) {
        const updatedSocialLinks = { ...this.formData.socialLinks };
        brandLinks.forEach((linkObj) => {
          if (!linkObj.name || !linkObj.url) return;
          const nameLower = linkObj.name.toLowerCase();
          if (nameLower.includes("twitter")) {
            updatedSocialLinks.x = linkObj.url;
          }
          if (nameLower.includes("facebook")) {
            updatedSocialLinks.facebook = linkObj.url;
          }
          if (nameLower.includes("linkedin")) {
            updatedSocialLinks.linkedin = linkObj.url;
          }
          if (nameLower.includes("instagram")) {
            updatedSocialLinks.instagram = linkObj.url;
          }
          if (nameLower.includes("behance")) {
            updatedSocialLinks.behance = linkObj.url;
          }
          if (nameLower.includes("coffee")) {
            updatedSocialLinks.coffee = linkObj.url;
          }
          if (nameLower.includes("diaspora")) {
            updatedSocialLinks.diaspora = linkObj.url;
          }
          if (nameLower.includes("github")) {
            updatedSocialLinks.github = linkObj.url;
          }
          if (nameLower.includes("gitlab")) {
            updatedSocialLinks.gitlab = linkObj.url;
          }
          if (nameLower.includes("gitter")) {
            updatedSocialLinks.gitter = linkObj.url;
          }
          if (nameLower.includes("heart")) {
            updatedSocialLinks.heart = linkObj.url;
          }
          if (nameLower.includes("mastodon")) {
            updatedSocialLinks.mastodon = linkObj.url;
          }
          if (nameLower.includes("menu")) {
            updatedSocialLinks.menu = linkObj.url;
          }
          if (nameLower.includes("ok")) {
            updatedSocialLinks.ok = linkObj.url;
          }
          if (nameLower.includes("reddit")) {
            updatedSocialLinks.reddit = linkObj.url;
          }
          if (nameLower.includes("skype")) {
            updatedSocialLinks.skype = linkObj.url;
          }
          if (nameLower.includes("slack")) {
            updatedSocialLinks.slack = linkObj.url;
          }
          if (nameLower.includes("stackoverflow")) {
            updatedSocialLinks.stackoverflow = linkObj.url;
          }
          if (nameLower.includes("telegram")) {
            updatedSocialLinks.telegram = linkObj.url;
          }
          if (nameLower.includes("vk")) {
            updatedSocialLinks.vk = linkObj.url;
          }
          if (nameLower.includes("whatsapp")) {
            updatedSocialLinks.whatsapp = linkObj.url;
          }
          if (nameLower.includes("www")) {
            updatedSocialLinks.www = linkObj.url;
          }
          if (
            nameLower === "x" ||
            nameLower.includes("x (") ||
            nameLower.includes(" x")
          ) {
            updatedSocialLinks.x = linkObj.url;
          }
          if (nameLower.includes("xing")) {
            updatedSocialLinks.xing = linkObj.url;
          }
          if (nameLower.includes("youtube")) {
            updatedSocialLinks.youtube = linkObj.url;
          }
        });
        updates.socialLinks = updatedSocialLinks;
      }

      const templatesAllowed = ["template2", "template3", "template7", "template8", "template13"];
      if (
        templatesAllowed.includes(this.formData.selectedTemplate) &&
        brandColors.length > 0
      ) {
        let availableHexes = brandColors.map((c) => c.hex);
        const usedColors = [];
        if (this.formData.mainColor) usedColors.push(this.formData.mainColor);
        if (this.formData.secondaryColor) usedColors.push(this.formData.secondaryColor);
        if (this.formData.socialIconColor) usedColors.push(this.formData.socialIconColor);

        availableHexes = availableHexes.filter((hex) => !usedColors.includes(hex));
        if (availableHexes.length === 0) {
          availableHexes = brandColors.map((c) => c.hex);
        }
        updates.descriptionBackground = availableHexes[Math.floor(Math.random() * availableHexes.length)];
      }

      this.$emit("update-form-data", updates);
    },
    pickCohesiveColors(brandColors) {
      if (!Array.isArray(brandColors) || brandColors.length === 0) {
        return null;
      }
      function isWhite(hex) {
        return hex.trim().toLowerCase() === "#ffffff";
      }
      function parseHexToRGB(hex) {
        let c = hex.replace(/^#/, "");
        if (c.length === 3) {
          c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
        }
        const num = parseInt(c, 16);
        return { r: (num >> 16) & 255, g: (num >> 8) & 255, b: num & 255 };
      }
      function colorDistance(hexA, hexB) {
        const c1 = parseHexToRGB(hexA);
        const c2 = parseHexToRGB(hexB);
        return Math.sqrt(
          Math.pow(c1.r - c2.r, 2) +
          Math.pow(c1.g - c2.g, 2) +
          Math.pow(c1.b - c2.b, 2)
        );
      }
      const allHex = brandColors.map((c) => c.hex);

      function pickRandomColor(allowWhite = false) {
        let available = allowWhite ? allHex : allHex.filter((hex) => !isWhite(hex));
        if (available.length === 0) {
          available = allHex;
        }
        const idx = Math.floor(Math.random() * available.length);
        return available[idx];
      }

      let availableNonWhite = brandColors.filter((c) => !isWhite(c.hex));
      let mainColorHex;
      if (availableNonWhite.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableNonWhite.length);
        mainColorHex = availableNonWhite[randomIndex].hex;
      } else {
        const randomIndex = Math.floor(Math.random() * brandColors.length);
        mainColorHex = brandColors[randomIndex].hex;
      }

      let secondColorHex = mainColorHex;
      if (brandColors.length > 1) {
        let nonWhiteHexes = allHex.filter((hex) => !isWhite(hex));
        if (nonWhiteHexes.length === 0) {
          nonWhiteHexes = allHex;
        }
        let minDist = Infinity;
        let closestHex = mainColorHex;
        for (const candidateHex of nonWhiteHexes) {
          if (candidateHex === mainColorHex) continue;
          const dist = colorDistance(mainColorHex, candidateHex);
          if (dist < minDist) {
            minDist = dist;
            closestHex = candidateHex;
          }
        }
        secondColorHex = closestHex;
      }

      const borderColor = pickRandomColor(false);
      const plannedAbsenceColor = pickRandomColor(true);
      let plannedAbsenceTextColor = pickRandomColor(true);
      {
        let iterations = 0;
        while (plannedAbsenceTextColor === plannedAbsenceColor && iterations < 10) {
          plannedAbsenceTextColor = pickRandomColor(true);
          iterations++;
        }
      }
      const socialIconColor = pickRandomColor(false);
      {
        let iterations = 0;
        while (secondColorHex === socialIconColor && iterations < 10) {
          secondColorHex = pickRandomColor(false);
          iterations++;
        }
      }
      let meetingColor = pickRandomColor(true);
      {
        let iterations = 0;
        while (meetingColor === socialIconColor && iterations < 10) {
          meetingColor = pickRandomColor(true);
          iterations++;
        }
      }

      const { r, g, b } = (function (hex) {
        let c = hex.replace(/^#/, "");
        if (c.length === 3) {
          c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2];
        }
        const num = parseInt(c, 16);
        return { r: (num >> 16) & 255, g: (num >> 8) & 255, b: num & 255 };
      })(socialIconColor);
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      const isWhiteIcons = luminance < 128;

      return {
        mainColor: mainColorHex,
        secondaryColor: secondColorHex,
        borderColor: borderColor,
        plannedAbsenceColor: plannedAbsenceColor,
        plannedAbsenceTextColor: plannedAbsenceTextColor,
        meetingColor: meetingColor,
        contactIconColor: socialIconColor,
        socialIconColor: socialIconColor,
        addonsColor: pickRandomColor(false),
        chatColor: socialIconColor,
        isWhiteIcons: isWhiteIcons,
        isWhiteContactIcons: isWhiteIcons,
      };
    },
  },
};
</script>

<style scoped>
.brand-fetcher {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  min-width: 220px !important;
  padding: 0.6rem;
  flex: 1;
}

.brand-fetcher input.pulsating {
  border: 1px solid #ff6a00;
  animation: border-pulse 2s infinite;
}

@keyframes border-pulse {
  0% {
    border-color: #ff6a00;
  }

  50% {
    border-color: rgba(255, 106, 0, 0);
  }

  100% {
    border-color: #ff6a00;
  }
}

.copy-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  color: #888;
}

.copy-link:hover {
  color: #ff6a00;
}

.brand-error-toast {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  background-color: #e74c3c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.6s;
  z-index: 1000;
}

.button-container {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  min-width: 120px;
  padding: 0.5rem;
  text-align: center;
}

@media (max-width: 997px) {
  .brand-fetcher {
    margin-bottom: 1rem;
  }

  .input-container input {
    min-width: 100% !important;
  }
}
</style>
