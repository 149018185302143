<template>
  <div class="modal-overlay" v-if="show" @click.self="closeModal">
    <div class="modal-content">
      <h3>{{ $t('modalAdd.addNewField') }}</h3>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="label">
            {{ $t('modalAdd.title') }}:
          </label>
          <div class="error">
            <input type="text" id="label" v-model="newFieldTitle" class="input-field"
              :placeholder="$t('modalAdd.enterTitle')" :class="{ 'input-error': errorTitle }" />
            <span v-if="errorTitle" class="error-message">
              {{ $t('modalAdd.titleIsRequired') }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="value">
            {{ $t('modalAdd.value') }}:
          </label>
          <input type="text" id="value" v-model="newFieldValue" class="input-field"
            :placeholder="$t('modalAdd.enterValue')" />
        </div>
        <div class="form-group">
          <label for="label">
            {{ $t('modalAdd.label') }}:
          </label>
          <input type="text" id="label" v-model="newFieldLabel" class="input-field"
            :placeholder="$t('modalAdd.enterLabel')" />
        </div>
        <div class="form-group">
          <label for="value">
            {{ $t('modalAdd.icon') }}:
          </label>
          <input type="text" id="value" v-model="newFieldIcon" class="input-field"
            :placeholder="$t('modalAdd.enterIconUrl')" />
        </div>
        <div class="form-group">
          <label for="hrefType">
            {{ $t('modalAdd.typeHref') }}:
          </label>
          <div class="select-wrapper">
            <select id="hrefType" v-model="newFieldHrefType" class="input-field select-custom">
              <option value="url">URL</option>
              <option value="tel">tel:</option>
              <option value="mailto">mailto:</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="href">
            {{ $t('modalAdd.href') }}:
          </label>
          <input type="text" id="href" v-model="newFieldHref" class="input-field" :placeholder="getHrefPlaceholder" />
        </div>
        <div class="form-actions">
          <button type="button" class="btn-cancel" @click="closeModal">
            {{ $t('common.cancel') }}
          </button>
          <button type="submit" class="btn-save">
            {{ $t('common.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAdd",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newFieldTitle: "",
      newFieldLabel: "",
      newFieldValue: "",
      newFieldIcon: "",
      newFieldHref: "",
      newFieldHrefType: "url",
      errorTitle: false,
    };
  },
  computed: {
    getHrefPlaceholder() {
      switch (this.newFieldHrefType) {
        case "tel":
          return "Enter phone number";
        case "mailto":
          return "Enter email address";
        default:
          return "Enter URL";
      }
    },
  },
  methods: {
    closeModal() {
      this.errorTitle = false;
      this.$emit("close");
    },
    handleSubmit() {
      if (!this.newFieldTitle.trim()) {
        this.errorTitle = true;
        return;
      }

      this.$emit("add-field", {
        title: this.newFieldTitle,
        label: this.newFieldLabel,
        value: this.newFieldValue,
        icon: this.newFieldIcon,
        href: this.newFieldHref,
        hrefType: this.newFieldHrefType,
      });

      this.newFieldTitle = "";
      this.newFieldLabel = "";
      this.newFieldValue = "";
      this.newFieldIcon = "";
      this.newFieldHref = "";
      this.newFieldHrefType = "url";
      this.errorTitle = false;

      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal-content {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
}

.modal-content h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.error {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-weight: bold;
  color: #666;
}

.input-field {
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.input-field:focus {
  outline: none;
  border-color: #ff6a00;
}

.input-error {
  border-color: #f44336;
}

.error-message {
  color: #f44336;
  font-size: 0.875rem;
}

.select-wrapper {
  position: relative;
}

.select-custom {
  appearance: none;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23777" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.select-custom:focus {
  border-color: #ff6a00;
}

.select-custom option {
  padding: 0.75rem;
  background-color: white;
  color: black;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1rem;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel:hover {
  background-color: #d32f2f;
}

.btn-save {
  background-color: #4caf50;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-save:hover {
  background-color: #388e3c;
}

.dark-mode .modal-content {
  background: #333333;
  color: #ddd;
}

.dark-mode .modal-content h3 {
  color: #ddd;
  border-bottom-color: #555;
}

.dark-mode .input-field {
  background-color: #555;
  border-color: #777;
  color: #ddd;
}

.dark-mode .input-field:focus {
  border-color: #ff6a00;
}

.dark-mode .select-custom {
  background-color: #555;
  border-color: #777;
  color: #ddd;
}

.dark-mode .select-custom option {
  background-color: #555;
  color: #ddd;
}

.dark-mode .btn-cancel {
  background-color: #ff6b6b;
}

.dark-mode .btn-cancel:hover {
  background-color: #ff4c4c;
}

.dark-mode .btn-save {
  background-color: #66bb6a;
}

.dark-mode .btn-save:hover {
  background-color: #4caf50;
}
</style>
