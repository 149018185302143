<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t('modalReset.resetSettings') }}</h2>
      </div>
      <div class="modal-body">
        <p>{{ $t('modalReset.areYouSureReset') }}</p>
      </div>
      <div class="modal-footer button-container">
        <button class="action-button" @click="$emit('confirm')">
          {{ $t('common.yes') }}
        </button>
        <button class="action-button" @click="$emit('close')">
          {{ $t('common.no') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style scoped>
.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  border-radius: 20px;
  max-width: 900px;
  max-height: 80vh;
}

.modal-body {
  padding-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #333333, #444444);
  color: #ddd;
}
</style>
