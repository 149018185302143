<template>
    <div class="language-selector">
        <select id="lang" v-model="selectedLang" @change="changeLanguage">
            <option value="en">English</option>
            <option value="pl">Polski</option>
            <option value="es">Español</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "LanguageSelector",
    data() {
        return {
            selectedLang: this.$i18n.locale
        };
    },
    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLang;
            localStorage.setItem("userLocale", this.selectedLang);
        }
    },
};
</script>

<style scoped>
.language-selector {
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.language-selector select {
    width: 120px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px 12px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #333;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.language-selector select:hover {
    border-color: #888;
}

.language-selector select:focus {
    border-color: #ff6a00;
}

.dark-mode .language-selector select {
    background-color: #444;
    border: 1px solid #666;
    color: #ddd;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23ddd" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
}

.dark-mode .language-selector select:hover {
    border-color: #aaa;
}

.dark-mode .language-selector select:focus {
    border-color: #ff6a00;
}
</style>