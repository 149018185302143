import { createApp } from "vue";
import App from "./App.vue";
import router from "./app-routing";
import Clarity from "@microsoft/clarity";
import { createI18n } from "vue-i18n";
import pl from "./assets/i18n/pl.json";
import es from "./assets/i18n/es.json";
import en from "./assets/i18n/en.json";
import "./style.scss";

const projectId = "pfibikw815";
Clarity.init(projectId);

let userLocale = localStorage.getItem("userLocale");

if (!userLocale) {
    const browserLocale = navigator.language || navigator.userLanguage;
    if (browserLocale.startsWith("pl")) {
        userLocale = "pl";
    } else if (browserLocale.startsWith("es")) {
        userLocale = "es";
    } else {
        userLocale = "en";
    }
    localStorage.setItem("userLocale", userLocale);
}

const i18n = createI18n({
    locale: userLocale,
    fallbackLocale: "en",
    messages: {
        pl,
        es,
        en,
    },
    missing: (locale, key) => {
        console.warn(`Missing translation for key "${key}" in locale "${locale}"`);
        return key;
    },
});

const app = createApp(App);
app.use(router);
app.use(i18n);
app.mount("#app");
