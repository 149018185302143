<template>
  <div class="social-display">
    <!-- CONTACT ICONS -->
    <div class="contact-icons">
      <div class="header">
        <h2>{{ $t('generatorIcons.contactIcons') }}</h2>
        <div class="button-group">
          <button :class="['toggle-color-button', { active: isWhiteContactIcons }]" @click="toggleContactIconColor">
            {{ $t('generatorIcons.changeIconColor') }}
          </button>
          <button v-if="selectedContactIcons.length > 0" class="reset-button" @click="resetContactIconsSelection">
            {{ $t('generatorIcons.resetSelection') }}
          </button>
        </div>
      </div>

      <div class="icon-grid">
        <div v-for="icon in contactIconList" :key="icon" class="icon-item">
          <label :class="[
            { selected: isSelectedContact(icon) },
            { 'white-icon-bg': isWhiteContactIcons },
          ]">
            <input type="checkbox" :value="icon" @change="toggleContactIcon(icon)" />
            <span v-if="contactLoadingStates[icon]" class="loader-container">
              <LoaderApp :isLoading="contactLoadingStates[icon]" />
            </span>
            <img v-else :src="getContactIconLink(icon)" :alt="icon" class="icon" @load="handleContactImageLoad(icon)"
              @error="handleContactImageError(icon)" />
            <span>{{ icon }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="social-icons">
      <div class="header">
        <h2>{{ $t('generatorIcons.socialIcons') }}</h2>
        <div class="button-group">
          <button :class="['toggle-color-button', { active: isWhiteIcons }]" @click="toggleIconColor">
            {{ $t('generatorIcons.changeIconColor') }}
          </button>
          <button v-if="selectedIcons.length > 0" class="reset-button" @click="resetSelection">
            {{ $t('generatorIcons.resetSelection') }}
          </button>
        </div>
      </div>

      <!-- SOCIAL ICONS -->
      <div class="icon-grid">
        <div v-for="icon in icons.concat(customIcons.map((c) => c.name))" :key="icon" class="icon-item">
          <label :class="[
            { selected: isSelectedSocial(icon) },
            { 'white-icon-bg': isWhiteIcons },
          ]">
            <input type="checkbox" :value="icon" @change="toggleSocialIcon(icon)" />
            <span v-if="loadingStates[icon]" class="loader-container">
              <LoaderApp :isLoading="loadingStates[icon]" />
            </span>
            <img v-else :src="getSocialIconLink(icon)" :alt="icon" class="icon" @load="handleImageLoad(icon)"
              @error="handleImageError(icon)" />
            <span>{{ icon }}</span>
          </label>
          <input v-if="isSelectedSocial(icon)" type="text" v-model="links[icon]"
            :placeholder="$t('generatorIcons.enterProfileURL')" @input="updateSocialLink(icon)"
            class="profile-link-input" />
        </div>
      </div>
    </div>

    <div class="add-custom-icon">
      <h3>{{ $t('generatorIcons.addCustomSocialMediaIcon') }}</h3>
      <input type="text" v-model="newIconName" :placeholder="$t('generatorIcons.iconName')" class="custom-icon-input" />
      <input type="text" v-model="newIconUrl" :placeholder="$t('generatorIcons.iconImageURL')"
        class="custom-icon-input" />
      <button @click="addCustomIcon" class="add-button">
        {{ $t('generatorIcons.addIcon') }}
      </button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import LoaderApp from "../components/loader/LoaderView.vue";

export default {
  props: {
    // SOCIAL
    selectedIcons: Array,
    socialLinks: Object,
    customIcons: Array,
    iconLinks: Object,
    isWhiteIcons: Boolean,
    selectedContactIcons: Array,
    contactIconLinksBlack: Object,
    contactIconLinksWhite: Object,
    isWhiteContactIcons: Boolean,
  },
  components: {
    LoaderApp,
  },
  data() {
    return {
      icons: [
        "behance",
        "coffee",
        "diaspora",
        "facebook",
        "github",
        "gitlab",
        "gitter",
        "heart",
        "instagram",
        "linkedin",
        "mastodon",
        "menu",
        "ok",
        "reddit",
        "skype",
        "slack",
        "stackoverflow",
        "telegram",
        "vk",
        "whatsapp",
        "www",
        "x",
        "xing",
        "youtube",
      ],
      links: reactive({ ...this.socialLinks }),
      newIconName: "",
      newIconUrl: "",
      loadingStates: reactive({}),
      contactIconList: ["phone", "mobile", "email", "chat", "meeting"],
      contactLoadingStates: reactive({}),
    };
  },
  mounted() {
    this.icons.concat(this.customIcons.map((c) => c.name)).forEach((icon) => {
      this.loadingStates[icon] = true;
      const img = new Image();
      img.src = this.getSocialIconLink(icon);
      img.onload = () => this.handleImageLoad(icon);
      img.onerror = () => this.handleImageError(icon);
    });

    this.contactIconList.forEach((icon) => {
      this.contactLoadingStates[icon] = true;
      const img = new Image();
      img.src = this.getContactIconLink(icon);
      img.onload = () => this.handleContactImageLoad(icon);
      img.onerror = () => this.handleContactImageError(icon);
    });
  },
  methods: {
    isSelectedSocial(icon) {
      return this.selectedIcons.includes(icon);
    },
    toggleSocialIcon(icon) {
      if (this.isSelectedSocial(icon)) {
        this.$emit(
          "update-icons",
          this.selectedIcons.filter((i) => i !== icon)
        );
      } else {
        this.$emit("update-icons", [...this.selectedIcons, icon]);
      }
    },
    updateSocialLink(icon) {
      this.$emit("update-links", { ...this.links, [icon]: this.links[icon] });
    },
    resetSelection() {
      this.$emit("update-icons", []);
      this.$emit("update-links", {});
    },
    addCustomIcon() {
      if (this.newIconName && this.newIconUrl) {
        this.$emit("add-custom-icon", {
          name: this.newIconName,
          url: this.newIconUrl,
        });
        this.newIconName = "";
        this.newIconUrl = "";
      }
    },
    toggleIconColor() {
      this.$emit("toggle-icon-color");
    },
    getSocialIconLink(icon) {
      const custom = this.customIcons.find((c) => c.name === icon);
      if (custom) return custom.url;
      return this.iconLinks[icon] || "";
    },
    handleImageLoad(icon) {
      this.loadingStates[icon] = false;
    },
    handleImageError(icon) {
      console.error(`Failed to load icon: ${icon}`);
      this.loadingStates[icon] = false;
    },
    isSelectedContact(icon) {
      return this.selectedContactIcons.includes(icon);
    },
    toggleContactIcon(icon) {
      let newArray = [...this.selectedContactIcons];
      if (newArray.includes(icon)) {
        newArray = newArray.filter((i) => i !== icon);
      } else {
        newArray.push(icon);
      }
      this.$emit("update", { selectedContactIcons: newArray });
    },
    toggleContactIconColor() {
      this.$emit("toggle-contact-icon-color");
    },
    resetContactIconsSelection() {
      this.$emit("reset-contact-icons");
    },
    getContactIconLink(icon) {
      if (this.isWhiteContactIcons) {
        return this.contactIconLinksWhite[icon] || "";
      } else {
        return this.contactIconLinksBlack[icon] || "";
      }
    },
    handleContactImageLoad(icon) {
      this.contactLoadingStates[icon] = false;
    },
    handleContactImageError(icon) {
      console.error(`Failed to load contact icon: ${icon}`);
      this.contactLoadingStates[icon] = false;
    },
  },
  watch: {
    socialLinks: {
      handler(newLinks) {
        Object.assign(this.links, newLinks);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.social-display {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  transition: border-color 0.5s;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  transition: color 0.5s;
  text-wrap: nowrap;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.5rem;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  position: relative;
}

label.selected {
  background-color: #e0e0e0;
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label.white-icon-bg {
  background-color: #cccccc;
}

label.selected.white-icon-bg {
  background-color: #999999;
  border-color: #666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 32px;
  height: 32px;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] {
  display: none;
}

.profile-link-input {
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.profile-link-input:focus {
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
}

.reset-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.reset-button:hover {
  background-color: #333;
  color: #fff;
}

.toggle-color-button {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  color: #333;
  border: 2px solid #333;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.toggle-color-button.active {
  background-color: #333;
  color: #fff;
}

.toggle-color-button:hover {
  background-color: #555;
  color: #fff;
}

.add-custom-icon {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-icon-input {
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-icon-input:focus {
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  outline: none;
}

.add-button {
  padding: 0.5rem 1rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.add-button:hover {
  background-color: #555;
}

.dark-mode .header {
  border-color: #555;
}

.dark-mode .header h2 {
  color: #ddd;
}

.dark-mode label {
  background-color: #444;
  color: #ddd;
}

.dark-mode label.selected {
  background-color: #555;
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.dark-mode .profile-link-input {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .profile-link-input:focus {
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  outline: none;
}

.dark-mode .reset-button {
  color: #ddd;
  border-color: #ddd;
}

.dark-mode .reset-button:hover {
  background-color: #ddd;
  color: #333;
}

.dark-mode .toggle-color-button {
  background-color: #555;
  color: #ddd;
  border-color: #ddd;
}

.dark-mode .toggle-color-button.active {
  background-color: #333;
  color: #fff;
}

.dark-mode .toggle-color-button:hover {
  background-color: #ddd;
  color: #333;
}

.dark-mode .custom-icon-input {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .custom-icon-input:focus {
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.dark-mode .add-button {
  background-color: #555;
}

.dark-mode .add-button:hover {
  background-color: #777;
}
</style>
