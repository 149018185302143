<template>
  <div id="app">
    <router-view></router-view>
  </div>

  <LiveChatWidget
      license="19021209"
      group="1"
      visibility="minimized"
      [customerName]="userName"
      [customerEmail]="userEmail"
  ></LiveChatWidget>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from "@/environments";
import { LiveChatWidget } from '@livechat/widget-vue'

export default {
  name: "App",
  components: {
    LiveChatWidget
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      axios
          .get(`${BACKEND_URL}/validate-token?token=${token}`)
          .then((response) => {
            if (response.data.valid) {
              sessionStorage.setItem('generatorAuthToken', token);
              window.history.replaceState(null, '', window.location.pathname);
              window.location.reload();
            } else {
              sessionStorage.removeItem('generatorAuthToken');
              console.error('Token is not valid:', response.data.error);
              window.history.replaceState(null, '', window.location.pathname);
            }
          })
          .catch((err) => {
            sessionStorage.removeItem('generatorAuthToken');
            console.error('Error verifying token:', err);
            window.history.replaceState(null, '', window.location.pathname);
          });
    }
    const params = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: params.get("utm_source") || "",
      utm_medium: params.get("utm_medium") || "",
      utm_campaign: params.get("utm_campaign") || "",
      utm_term: params.get("utm_term") || "",
      utm_content: params.get("utm_content") || ""
    };
    if (
        utmParams.utm_source ||
        utmParams.utm_medium ||
        utmParams.utm_campaign ||
        utmParams.utm_term ||
        utmParams.utm_content
    ) {
      localStorage.setItem("utm_params", JSON.stringify(utmParams));
    }
  },
};
</script>
