<template>
  <div class="header">
    <h2>{{ $t('generatorOptions.signatureOptions') }}</h2>
  </div>

  <div class="options-container">
    <!-- Main Color -->
    <div class="option">
      <label for="main-color">
        {{ $t('generatorOptions.mainColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="main-color" v-model="options.mainColor" @input="updateHexColor('mainColor')"
          class="color-input" />
        <input type="text" v-model="options.mainColor" @input="updateColor('mainColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Secondary Color -->
    <div class="option">
      <label for="secondary-color">
        {{ $t('generatorOptions.secondaryColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="secondary-color" v-model="options.secondaryColor"
          @input="updateHexColor('secondaryColor')" class="color-input" />
        <input type="text" v-model="options.secondaryColor" @input="updateColor('secondaryColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Border Color -->
    <div class="option">
      <label for="border-color">
        {{ $t('generatorOptions.borderColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="border-color" v-model="options.borderColor" @input="updateHexColor('borderColor')"
          class="color-input" />
        <input type="text" v-model="options.borderColor" @input="updateColor('borderColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
        <button @click="setTransparent('borderColor')" class="transparent-button">
          {{ $t('generatorOptions.transparent') }}
        </button>
      </div>
    </div>

    <!-- Description Background Color -->
    <div class="option">
      <label for="description-background">
        {{ $t('generatorOptions.backgroundColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="description-background" v-model="options.descriptionBackground"
          @input="updateHexColor('descriptionBackground')" class="color-input" />
        <input type="text" v-model="options.descriptionBackground" @input="updateColor('descriptionBackground')"
          maxlength="7" :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
        <button @click="setTransparent('descriptionBackground')" class="transparent-button">
          {{ $t('generatorOptions.transparent') }}
        </button>
      </div>
    </div>

    <!-- Meeting Text Color -->
    <div class="option">
      <label for="meeting-color">
        {{ $t('generatorOptions.meetingTextColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="meeting-color" v-model="options.meetingColor" @input="updateHexColor('meetingColor')"
          class="color-input" />
        <input type="text" v-model="options.meetingColor" @input="updateColor('meetingColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Chat Text Color -->
    <div class="option">
      <label for="chat-color">
        {{ $t('generatorOptions.chatTextColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="chat-color" v-model="options.chatColor" @input="updateHexColor('chatColor')"
          class="color-input" />
        <input type="text" v-model="options.chatColor" @input="updateColor('chatColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Contact Icon Color -->
    <div class="option">
      <label for="contact-icon-color">
        {{ $t('generatorOptions.contactIconColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="contact-icon-color" v-model="options.contactIconColor"
          @input="updateHexColor('contactIconColor')" class="color-input" />
        <input type="text" v-model="options.contactIconColor" @input="updateColor('contactIconColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
        <button @click="setTransparent('contactIconColor')" class="transparent-button">
          {{ $t('generatorOptions.transparent') }}
        </button>
      </div>
    </div>

    <!-- Social Media Icon Color -->
    <div class="option">
      <label for="social-icon-color">
        {{ $t('generatorOptions.socialIconColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="social-icon-color" v-model="options.socialIconColor"
          @input="updateHexColor('socialIconColor')" class="color-input" />
        <input type="text" v-model="options.socialIconColor" @input="updateColor('socialIconColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
        <button @click="setTransparent('socialIconColor')" class="transparent-button">
          {{ $t('generatorOptions.transparent') }}
        </button>
      </div>
    </div>

    <!-- Planned Absence Text Color -->
    <div class="option">
      <label for="planned-absence-text-color">
        {{ $t('generatorOptions.plannedAbsenceTextColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="planned-absence-text-color" v-model="options.plannedAbsenceTextColor"
          @input="updateHexColor('plannedAbsenceTextColor')" class="color-input" />
        <input type="text" v-model="options.plannedAbsenceTextColor" @input="updateColor('plannedAbsenceTextColor')"
          maxlength="7" :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Planned Absence Color -->
    <div class="option">
      <label for="planned-absence-color">
        {{ $t('generatorOptions.plannedAbsenceColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="planned-absence-color" v-model="options.plannedAbsenceColor"
          @input="updateHexColor('plannedAbsenceColor')" class="color-input" />
        <input type="text" v-model="options.plannedAbsenceColor" @input="updateColor('plannedAbsenceColor')"
          maxlength="7" :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
        <button @click="setTransparent('plannedAbsenceColor')" class="transparent-button">
          {{ $t('generatorOptions.transparent') }}
        </button>
      </div>
    </div>

    <!-- Addons Color -->
    <div class="option">
      <label for="addons-color">
        {{ $t('generatorOptions.addonsColor') }}:
      </label>
      <div class="color-picker-container">
        <input type="color" id="addons-color" v-model="options.addonsColor" @input="updateHexColor('addonsColor')"
          class="color-input" />
        <input type="text" v-model="options.addonsColor" @input="updateColor('addonsColor')" maxlength="7"
          :placeholder="$t('generatorOptions.enterColor')" class="text-input" />
      </div>
    </div>

    <!-- Font Family -->
    <div class="option">
      <label for="font-family">
        {{ $t('generatorOptions.fontFamily') }}:
      </label>
      <select id="font-family" v-model="options.fontFamily" class="select-menu">
        <option value="Arial, sans-serif">Arial</option>
        <option value="'Courier New', monospace">Courier New</option>
        <option value="'Times New Roman', serif">Times New Roman</option>
        <option value="'Georgia', serif">Georgia</option>
        <option value="Verdana, sans-serif">Verdana</option>
        <option value="'Trebuchet MS', sans-serif">Trebuchet MS</option>
        <option value="Tahoma, sans-serif">Tahoma</option>
        <option value="'Comic Sans MS', cursive">Comic Sans MS</option>
      </select>
    </div>

    <!-- Font Size -->
    <div class="option">
      <label for="font-size">
        {{ $t('generatorOptions.fontSize') }}:
      </label>
      <select id="font-size" v-model="options.fontSize" class="select-menu">
        <option value="small">{{ $t('generatorOptions.small') }}</option>
        <option value="medium">{{ $t('generatorOptions.medium') }}</option>
        <option value="large">{{ $t('generatorOptions.large') }}</option>
      </select>
    </div>

    <!-- Signature Width -->
    <div class="option">
      <label for="signature-width">
        {{ $t('generatorOptions.signatureWidth') }}:
      </label>
      <div class="range-container">
        <input type="range" id="signature-width" min="200" max="800" step="10" v-model="signatureWidth"
          class="range-slider" />
        <span class="tooltip">{{ signatureWidth }}px</span>
      </div>
    </div>

    <!-- Toggle Profile Image -->
    <div class="option toggle">
      <label for="show-profile-image">
        {{ $t('generatorOptions.showProfileImage') }}:
      </label>
      <label class="switch">
        <input type="checkbox" id="show-profile-image" v-model="showProfileImage" />
        <span class="slider round"></span>
      </label>
    </div>

    <!-- Profile Image Size -->
    <div class="option">
      <label for="profile-image-size">
        {{ $t('generatorOptions.profileImageSize') }}:
      </label>
      <div class="range-container">
        <input type="range" id="profile-image-size" min="80" max="200" step="10" v-model="profileImageSize"
          class="range-slider" />
        <span class="tooltip">{{ profileImageSize }}px</span>
      </div>
    </div>

    <!-- Profile Image Shape -->
    <div class="option">
      <label for="profile-image-shape">
        {{ $t('generatorOptions.profileImageShape') }}:
      </label>
      <div class="range-container">
        <input type="range" id="profile-image-shape" min="0" max="50" step="1" v-model="profileImageShape"
          class="range-slider" />
        <span class="tooltip">{{ profileImageShape }}%</span>
      </div>
    </div>

    <!-- Contact Icon Size -->
    <div class="option">
      <label for="contact-icon-size">
        {{ $t('generatorOptions.contactIconSize') }}:
      </label>
      <div class="range-container">
        <input type="range" id="contact-icon-size" min="12" max="28" step="2" v-model="contactIconSize"
          class="range-slider" />
        <span class="tooltip">{{ contactIconSize }}px</span>
      </div>
    </div>

    <!-- Contact Icon Shape -->
    <div class="option">
      <label for="contact-icon-shape">
        {{ $t('generatorOptions.contactIconShape') }}:
      </label>
      <div class="range-container">
        <input type="range" id="contact-icon-shape" min="0" max="50" step="1" v-model="contactIconShape"
          class="range-slider" />
        <span class="tooltip">{{ contactIconShape }}%</span>
      </div>
    </div>

    <!-- Toggle Text Social Media -->
    <div class="option toggle">
      <label for="show-text-social-icon">
        {{ $t('generatorOptions.showTextSocialIcon') }}:
      </label>
      <label class="switch">
        <input type="checkbox" id="show-text-social-icon" v-model="socialIconText" />
        <span class="slider round"></span>
      </label>
    </div>

    <!-- Social Media Icon Size -->
    <div class="option">
      <label for="social-icon-size">
        {{ $t('generatorOptions.socialIconSize') }}:
      </label>
      <div class="range-container">
        <input type="range" id="social-icon-size" min="12" max="28" step="2" v-model="socialIconSize"
          class="range-slider" />
        <span class="tooltip">{{ socialIconSize }}px</span>
      </div>
    </div>

    <!-- Social Media Icon Shape -->
    <div class="option">
      <label for="social-icon-shape">
        {{ $t('generatorOptions.socialIconShape') }}:
      </label>
      <div class="range-container">
        <input type="range" id="social-icon-shape" min="0" max="50" step="1" v-model="socialIconShape"
          class="range-slider" />
        <span class="tooltip">{{ socialIconShape }}%</span>
      </div>
    </div>

    <!-- Toggle Logo -->
    <div class="option toggle">
      <label for="show-logo">
        {{ $t('generatorOptions.showLogo') }}:
      </label>
      <label class="switch">
        <input type="checkbox" id="show-logo" v-model="showLogo" />
        <span class="slider round"></span>
      </label>
    </div>

    <!-- Logo Size -->
    <div class="option">
      <label for="logo-size">
        {{ $t('generatorOptions.logoSize') }}:
      </label>
      <div class="range-container">
        <input type="range" id="logo-size" min="80" max="200" step="5" v-model="logoSize" class="range-slider" />
        <span class="tooltip">{{ logoSize }}px</span>
      </div>
    </div>

    <!-- Toggle Disclaimer Width -->
    <div class="option toggle">
      <label for="show-disclaimer">
        {{ $t('generatorOptions.disclaimerFullWidth') }}:
      </label>
      <label class="switch">
        <input type="checkbox" id="show-logo" v-model="disclaimerWidth" />
        <span class="slider round"></span>
      </label>
    </div>

    <!-- Banner Width -->
    <div class="option">
      <label for="banner-width">
        {{ $t('generatorOptions.bannerWidth') }}:
      </label>
      <div class="range-container">
        <input type="range" id="banner-width" min="200" max="800" step="10" v-model="bannerWidth"
          @input="updateBannerWidth" class="range-slider" />
        <span class="tooltip">{{ bannerWidth }}px</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignatureOptions",
  props: {
    formData: Object,
  },
  data() {
    return {
      defaultOptions: {
        showProfileImage: this.formData.showProfileImage,
        mainColor: this.formData.mainColor,
        secondaryColor: this.formData.secondaryColor,
        descriptionBackground: this.formData.descriptionBackground,
        socialIconText: this.formData.socialIconText,
        socialIconColor: this.formData.socialIconColor,
        plannedAbsenceColor: this.formData.plannedAbsenceColor,
        plannedAbsenceTextColor: this.formData.plannedAbsenceTextColor,
        socialIconShape: this.formData.socialIconShape,
        signatureWidth: this.formData.signatureWidth,
        fontFamily: this.formData.fontFamily,
        fontSize: this.formData.fontSize,
        profileImageSize: this.formData.profileImageSize,
        profileImageShape: this.formData.profileImageShape,
        showLogo: this.formData.showLogo,
        disclaimerWidth: this.formData.disclaimerWidth,
        logoSize: this.formData.logoSize,
        socialIconSize: this.formData.socialIconSize,
        borderColor: this.formData.borderColor,
      },
      showProfileImage: this.formData.showProfileImage,
      signatureWidth: this.formData.signatureWidth,
      socialIconSize: this.formData.socialIconSize,
      options: {
        mainColor: this.formData.mainColor,
        secondaryColor: this.formData.secondaryColor,
        descriptionBackground: this.formData.descriptionBackground,
        socialIconColor: this.formData.socialIconColor,
        plannedAbsenceColor: this.formData.plannedAbsenceColor,
        plannedAbsenceTextColor: this.formData.plannedAbsenceTextColor,
        fontFamily: this.formData.fontFamily,
        fontSize: this.formData.fontSize,
        borderColor: this.formData.borderColor,
        addonsColor: this.formData.addonsColor,
        meetingColor: this.formData.meetingColor,
        chatColor: this.formData.chatColor,
      },
      profileImageSize: this.formData.profileImageSize,
      profileImageShape: this.formData.profileImageShape,
      socialIconShape: this.formData.socialIconShape,
      socialIconText: this.formData.socialIconText,
      contactIconColor: this.formData.contactIconColor,
      contactIconSize: this.formData.contactIconSize,
      contactIconShape: this.formData.contactIconShape,
      showLogo: this.formData.showLogo,
      disclaimerWidth: this.formData.disclaimerWidth,
      logoSize: this.formData.logoSize,
      bannerWidth: this.formData.bannerWidth,
      meetingWidth: this.formData.meetingWidth,
    };
  },
  methods: {
    setTransparent(option) {
      this.options[option] = "transparent";
      this.$emit(option + "Change", "transparent");
    },
    updateHexColor(option) {
      this.$emit(option + "Change", this.options[option]);
    },
    updateColor(option) {
      const color = this.options[option];
      if (/^#[0-9A-F]{6}$/i.test(color)) {
        this.$emit(option + "Change", color);
      }
    },
  },
  watch: {
    formData: {
      handler(newVal) {
        this.showProfileImage = newVal.showProfileImage;
        this.signatureWidth = newVal.signatureWidth;
        this.socialIconText = newVal.socialIconText;
        this.socialIconSize = newVal.socialIconSize;
        this.profileImageSize = newVal.profileImageSize;
        this.profileImageShape = newVal.profileImageShape;
        this.socialIconShape = newVal.socialIconShape;
        this.contactIconColor = newVal.contactIconColor;
        this.contactIconSize = newVal.contactIconSize;
        this.contactIconShape = newVal.contactIconShape;
        this.showLogo = newVal.showLogo;
        this.disclaimerWidth = newVal.disclaimerWidth;
        this.logoSize = newVal.logoSize;
        this.bannerWidth = newVal.bannerWidth;
        this.meetingWidth = newVal.meetingWidth;
        this.options = {
          mainColor: newVal.mainColor,
          secondaryColor: newVal.secondaryColor,
          descriptionBackground: newVal.descriptionBackground,
          contactIconColor: newVal.contactIconColor,
          socialIconColor: newVal.socialIconColor,
          plannedAbsenceColor: newVal.plannedAbsenceColor,
          plannedAbsenceTextColor: newVal.plannedAbsenceTextColor,
          fontFamily: newVal.fontFamily,
          fontSize: newVal.fontSize,
          borderColor: newVal.borderColor,
          addonsColor: newVal.addonsColor,
          meetingColor: newVal.meetingColor,
          chatColor: newVal.chatColor,
        };
      },
      deep: true,
      immediate: true,
    },
    "options.addonsColor"(newColor) {
      this.$emit("addonsColorChange", newColor);
    },
    "options.meetingColor"(newColor) {
      this.$emit("meetingColorChange", newColor);
    },
    "options.chatColor"(newColor) {
      this.$emit("chatColorChange", newColor);
    },
    bannerWidth(newValue) {
      this.$emit("bannerWidthChange", newValue);
    },
    meetingWidth(newValue) {
      this.$emit("meetingWidthChange", newValue);
    },
    "options.mainColor"(newColor) {
      this.$emit("mainColorChange", newColor);
    },
    "options.secondaryColor"(newColor) {
      this.$emit("secondaryColorChange", newColor);
    },
    "options.borderColor"(newColor) {
      this.$emit("borderColorChange", newColor);
    },
    "options.descriptionBackground"(newColor) {
      this.$emit("descriptionBackgroundChange", newColor);
    },
    socialIconText(newValue) {
      this.$emit("toggleSocialIconText", newValue);
    },
    "options.socialIconColor"(newColor) {
      this.$emit("socialIconColorChange", newColor);
    },
    "options.plannedAbsenceColor"(newColor) {
      this.$emit("plannedAbsenceColorChange", newColor);
    },
    "options.plannedAbsenceTextColor"(newColor) {
      this.$emit("plannedAbsenceTextColorChange", newColor);
    },
    socialIconShape(newValue) {
      this.$emit("socialIconShapeChange", newValue);
    },
    contactIconColor(newValue) {
      this.$emit("contactIconColorChange", newValue);
    },
    contactIconSize(newValue) {
      this.$emit("contactIconSizeChange", newValue);
    },
    contactIconShape(newValue) {
      this.$emit("contactIconShapeChange", newValue);
    },
    signatureWidth(newValue) {
      this.$emit("signatureWidthChange", newValue);
    },
    showProfileImage(newValue) {
      this.$emit("toggleProfileImage", newValue);
    },
    profileImageSize(newValue) {
      this.$emit("profileImageSizeChange", newValue);
    },
    profileImageShape(newValue) {
      this.$emit("profileImageShapeChange", newValue);
    },
    showLogo(newValue) {
      this.$emit("toggleLogo", newValue);
    },
    disclaimerWidth(newValue) {
      this.$emit("toggleDisclaimer", newValue);
    },
    logoSize(newValue) {
      this.$emit("logoSizeChange", newValue);
    },
    socialIconSize(newValue) {
      this.$emit("socialIconSizeChange", newValue);
    },
    "options.fontFamily"(newValue) {
      this.$emit("fontFamilyChange", newValue);
    },
    "options.fontSize"(newValue) {
      this.$emit("fontSizeChange", newValue);
    },
  },
};
</script>

<style scoped>
.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.option {
  flex: 1 1 48%;
  display: flex;
  flex-direction: column;
}

.option label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
  transition: color 0.5s;
}

.color-picker-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.color-input {
  padding: 0;
  width: 40px;
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
}

.text-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f1f1f1;
  color: #333;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #666;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.5s, border-color 0.5s;
}

.transparent-button:hover {
  background-color: #eee;
}

.range-container {
  position: relative;
  display: flex;
  align-items: center;
}

.range-slider {
  width: 80%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s, background-color 0.5s;
  border-radius: 4px;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #2196f3;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.range-slider:focus::-webkit-slider-thumb {
  background: #1e88e5;
}

.range-slider:hover {
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:checked+.slider:before {
  transform: translateX(20px);
}

.option select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.5s, color 0.5s;
}

.option select:hover {
  background-color: #eee;
}
</style>
