<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t('modalCode.emailSignatureCode') }}</h2>
        <button class="close-button" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            class="close-icon">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="button-container">
        <button class="action-button" @click="copyCode">
          {{ $t('modalCode.copyCode') }}
        </button>
        <button class="action-button" @click="downloadCode">
          {{ $t('modalCode.downloadCode') }}
        </button>
      </div>
      <pre class="code-container">
        <code ref="codeContent">{{ code }}</code>
      </pre>
    </div>
  </div>
  <div v-if="showToast" class="toast">
    {{ $t('modalCode.codeCopied') }}
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    code: String,
  },
  data() {
    return {
      showToast: false,
    };
  },
  methods: {
    copyCode() {
      const codeContent = this.$refs.codeContent;
      const range = document.createRange();
      range.selectNode(codeContent);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        document.execCommand("copy");
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 1000);
      } catch (err) {
        alert("Failed to copy code");
      }

      window.getSelection().removeAllRanges();
    },
    downloadCode() {
      const blob = new Blob([this.code], { type: "text/plain;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "email_signature_code.txt";
      a.click();
      URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>
.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  border-radius: 20px;
  max-width: 900px;
  max-height: 80vh;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.action-button {
  margin: 0 10px;
}

.code-container {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  color: #f8f8f2;
  font-size: 14px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #333333, #444444);
  color: #ddd;
}

.dark-mode h2 {
  color: #ddd;
}

.dark-mode .code-container {
  background: #444;
  color: #f8f8f2;
}
</style>
