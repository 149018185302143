const iconLinksBlack = {
  behance:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:24.377Z_18428c82-ebcb-4a63-9f0f-6beb2ee11026",
  coffee:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:26:44.908Z_55f58ebd-5bdd-44f3-9f18-0fd518a4665f",
  diaspora:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:10.648Z_3f65a699-3c98-4f6a-9f2f-9da1f40fd0f7",
  facebook:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:27:59.871Z_1e1e577b-79c3-405b-93e1-4676857c4b9a",
  github:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:13.756Z_8b87990c-c0f1-4964-a151-a23e84ce2095",
  gitlab:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:34.932Z_8ac01c5d-61e7-490b-b273-25773e4dd25f",
  gitter:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:47.947Z_ead866ff-fa7c-4a14-a629-77d6c8c6cd70",
  heart:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:28:59.427Z_a87699b4-054c-46f0-b41d-ffa3a8a4a3fe",
  instagram:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:15.830Z_fd078727-f6fe-414e-8226-28ae4cabd534",
  linkedin:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:28.491Z_84cc5ce2-d45a-4995-ac2b-239bc40b72b5",
  mastodon:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:43.677Z_ad5e7c92-866e-4f43-a897-57984e948e74",
  menu: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:29:59.613Z_1f61506f-3b54-45b1-b74f-54be2b4aa27a",
  ok: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:13.068Z_3d9d81dd-e78a-4e4e-886d-3be4d19093eb",
  reddit:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:27.268Z_a0d90373-f846-494f-881d-c6a9750eb8ee",
  skype:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:30:50.676Z_eb8a6fc1-ad4f-4d1a-af17-e9eeae0dd4e8",
  slack:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:06.146Z_405327e7-aa1f-4a71-8001-deaea5af08d8",
  stackoverflow:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:23.538Z_7bc449b2-08de-4fac-bbc0-37e9797977da",
  telegram:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:31:37.960Z_759375eb-50b8-472e-aa92-f0b1cfd05a8b",
  vk: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:09.724Z_a702319f-633e-452d-8f58-8b81a38a7838",
  whatsapp:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:25.512Z_62b025d8-9080-4cf8-b167-2aeae6f90255",
  www: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-27T06:44:11.163Z_30a34ae2-89c1-4bd4-8acb-fc7c1555324b",
  x: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T16:21:33.518Z_a49fd59c-cfcb-43d8-9d0b-ec528e2991b4",
  xing: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:42.328Z_4904923c-b82a-4ed2-be58-c55994aadaa9",
  youtube:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:32:54.794Z_9889edae-4a1d-4d77-b039-ed78e756ce99",
};

const iconLinksWhite = {
  behance:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:04.528Z_89f82a62-b75f-4a10-bf64-1dd104e7971f",
  coffee:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:21.783Z_f43f83f2-e276-489d-8303-8a8d0cc50952",
  diaspora:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:35:36.238Z_bd6531f3-b3bd-4f58-810d-4e471cd96825",
  facebook:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:07.326Z_733c7249-76cf-47cd-81d3-dc01fd7c16e2",
  github:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:19.479Z_4bb18b73-1e2e-42b4-bf41-deca918720b2",
  gitlab:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:43.815Z_7f8619a8-dc0a-48e2-9f21-45485b0b9208",
  gitter:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:36:58.102Z_a50622d2-1935-4b1e-9f93-b602d6c43e97",
  heart:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:12.618Z_2b59cbe8-8282-4dd8-b093-8bbd02e8b5dc",
  instagram:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:34.722Z_44df56f6-3301-4c62-bcd6-df220d761715",
  linkedin:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:37:51.133Z_d9298197-8198-4847-919b-24e5c169aa98",
  mastodon:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:19.128Z_3194a01d-1934-40a9-8358-a14fea059373",
  menu: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:34.502Z_cdb2cc08-8b5d-4091-894d-3c3110d74182",
  ok: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:38:47.805Z_3ac145d3-eea4-4d63-ace4-d227bea6b652",
  reddit:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:02.420Z_2e146e92-4698-4c5c-a22b-e9fb8c24d9f7",
  skype:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:18.012Z_3e4ff1d6-5816-4731-a078-b35734845dd6",
  slack:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:34.053Z_50381480-4ffe-4856-bcab-f7fc4ed1f558",
  stackoverflow:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:39:54.077Z_9ac562de-4043-4285-940b-9c1b987c3184",
  telegram:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:40:13.045Z_e66c97a0-c527-48c5-9906-c794e54a880b",
  vk: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:07.102Z_47c8dd04-8004-4e4c-b2a9-0f0fc360f6b0",
  whatsapp:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:22.167Z_057d568f-0879-4e49-994d-48295440e8fa",
  www: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-27T06:44:15.951Z_7d6dc70f-284c-410c-9d93-a9ee4f8a40df",
  x: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T16:21:38.919Z_54227580-dd6d-4678-a700-2786ad5d85cc",
  xing: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:37.633Z_944dc88c-4d5e-4b63-a700-8ff42192d4db",
  youtube:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:41:51.250Z_68f28ef9-abab-4ce7-b2c4-b6d4c6e02b84",
};

const contactIconLinksBlack = {
  phone: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:22:35.806Z_b3cae2d2-da0c-486f-9be2-9bddd7ef955b",
  mobile:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:22:54.066Z_049b52f3-619b-40d0-8e4b-d22430efba00",
  email:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:09.166Z_8083acf1-0b78-40d8-b1ab-45d640b8ffc2",
  chat: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:23.644Z_27f9b181-d452-43de-a4d7-89e145356a8c",
  meeting:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:38.153Z_52a6b432-5956-4f41-885b-823ef543e004",
};

const contactIconLinksWhite = {
  phone: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:22:42.192Z_b3c60f63-8155-4210-9596-7172c148b30c",
  mobile:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:00.930Z_526743f5-0224-462b-bbb3-4ecd8457d77b",
  email:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:14.967Z_a6e11bf2-ca36-443b-a2d8-7f29bc138ad4",
  chat: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:31.055Z_1a55a7cc-9ea6-4742-b346-38a058a94790",
  meeting:
    "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-21T14:23:47.061Z_59d233c0-6d6b-4e90-a011-f2664dbf0bf7",
};

export const templatesFormData = {
  template1: {
    selectedTemplateName: "template1",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#D9D9D9",
    signatureWidth: 480,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "Arial, sans-serif",
    socialIconText: false,
    socialIconColor: "#3e63dd",
    addonsColor: "#9F9F9F",
    meetingColor: "#FFFFFF",
    chatColor: "#3e63dd",
    bannerWidth: 480,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:41:19.095Z_87b23a3a-5f16-4379-9442-5c3ac9a66fa3",
      chatText: "Schedule your free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template1",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "#3e63dd",
  },
  template2: {
    selectedTemplateName: "template2",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "#cce3f8",
    borderColor: "#cce3f8",
    signatureWidth: 460,
    profileImageSize: 100,
    profileImageShape: 10,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 20,
    contactIconShape: 20,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "'Trebuchet MS', sans-serif",
    socialIconText: false,
    socialIconColor: "#0aa0ea",
    addonsColor: "#9F9F9F",
    meetingColor: "#ffffff",
    chatColor: "#000000",
    bannerWidth: 460,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template2",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram", "linkedin", "youtube"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
      linkedin: "https://linkedin.com",
      youtube: "https://youtube.com"
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email"],
    contactIconColor: "#0aa0ea",
  },
  template3: {
    selectedTemplateName: "template3",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "#f6dc90",
    borderColor: "#000000",
    signatureWidth: 520,
    profileImageSize: 140,
    profileImageShape: 0,
    logoSize: 140,
    socialIconSize: 22,
    socialIconShape: 20,
    contactIconShape: 20,
    contactIconSize: 20,
    fontSize: "medium",
    fontFamily: "'Trebuchet MS', sans-serif",
    socialIconText: false,
    socialIconColor: "transparent",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#000000",
    bannerWidth: 520,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template3",
    isWhiteIcons: false,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram", "linkedin", "youtube"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
      linkedin: "https://linkedin.com",
      youtube: "https://youtube.com"
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "meeting", "chat"],
    contactIconColor: "transparent",
  },
  template4: {
    selectedTemplateName: "template4",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#111111",
    signatureWidth: 480,
    profileImageSize: 100,
    profileImageShape: 0,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: `'Courier New', monospace`,
    socialIconText: false,
    socialIconColor: "#111111",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#111111",
    bannerWidth: 480,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T18:36:25.835Z_7879fd4d-878e-41e6-8376-754b837b9fda",
      chatText: "Schedule your free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template4",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "transparent",
  },
  template5: {
    selectedTemplateName: "template5",
    mainColor: "#4D3EC6",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#D9D9D9",
    signatureWidth: 480,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "small",
    fontFamily: "Verdana, sans-serif",
    socialIconText: false,
    socialIconColor: "#4D3EC6",
    addonsColor: "#9F9F9F",
    meetingColor: "#FFFFFF",
    chatColor: "#4D3EC6",
    bannerWidth: 480,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:00:13.235Z_92c35e3d-da95-4423-8e5b-3ca6856d85a3",
      chatText: "Schedule your free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template5",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["chat", "meeting"],
    contactIconColor: "#4D3EC6",
  },
  template6: {
    selectedTemplateName: "template6",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#D9D9D9",
    signatureWidth: 480,
    profileImageSize: 80,
    profileImageShape: 5,
    logoSize: 100,
    socialIconSize: 14,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "small",
    fontFamily: "Tahoma, sans-serif",
    socialIconText: true,
    socialIconColor: "#111111",
    addonsColor: "#9F9F9F",
    meetingColor: "#FFFFFF",
    chatColor: "#111111",
    bannerWidth: 480,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:11:57.258Z_5a3d6f77-a226-453d-bf7b-22987245c303",
      chatText: "Schedule your free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template6",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "#000000",
  },
  template7: {
    selectedTemplateName: "template7",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "#F1FAF7",
    borderColor: "#111111",
    signatureWidth: 480,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: `'Georgia', serif`,
    socialIconText: false,
    socialIconColor: "#2A8B6E",
    addonsColor: "#9F9F9F",
    meetingColor: "#2A8B6E",
    chatColor: "#2A8B6E",
    bannerWidth: 480,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T19:21:35.940Z_abfe5a5f-406b-4a29-ab37-eb8032fa68f4",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template7",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "transparent",
  },
  template8: {
    selectedTemplateName: "template8",
    mainColor: "#FFFFFF",
    secondaryColor: "#2E3349",
    descriptionBackground: "#2E3349",
    borderColor: "#A1A1A1",
    signatureWidth: 500,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "Arial, sans-serif",
    socialIconText: false,
    socialIconColor: "#2E3349",
    addonsColor: "#9F9F9F",
    meetingColor: "#FFFFFF",
    chatColor: "#2E3349",
    bannerWidth: 500,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon:
        "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2025-02-13T19:50:27.281Z_f1e3eaa0-a482-4eca-bd68-313e07032af1",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template8",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "#2E3349",
  },
  template9: {
    selectedTemplateName: "template9",
    mainColor: "#000000",
    secondaryColor: "#4285f4",
    descriptionBackground: "transparent",
    borderColor: "#4285f4",
    signatureWidth: 420,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "Arial, sans-serif",
    socialIconText: false,
    socialIconColor: "#4285f4",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#000000",
    bannerWidth: 420,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template9",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["chat", "meeting"],
    contactIconColor: "transparent",
  },
  template10: {
    selectedTemplateName: "template10",
    mainColor: "#70909c",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#70909c",
    signatureWidth: 420,
    profileImageSize: 140,
    profileImageShape: 0,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "'Times New Roman', serif",
    socialIconText: false,
    socialIconColor: "#70909c",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#000000",
    bannerWidth: 420,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template10",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["chat", "meeting"],
    contactIconColor: "transparent",
  },
  template11: {
    selectedTemplateName: "template11",
    mainColor: "#5B7864",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#5B7864",
    signatureWidth: 420,
    profileImageSize: 80,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "Arial, sans-serif",
    socialIconText: false,
    socialIconColor: "#5B7864",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#000000",
    bannerWidth: 420,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template11",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["chat", "meeting"],
    contactIconColor: "transparent",
  },
  template12: {
    selectedTemplateName: "template12",
    mainColor: "#7C9883",
    secondaryColor: "#000000",
    descriptionBackground: "transparent",
    borderColor: "#7C9883",
    signatureWidth: 460,
    profileImageSize: 120,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "'Courier New', monospace",
    socialIconText: false,
    socialIconColor: "#7C9883",
    addonsColor: "#9F9F9F",
    meetingColor: "#FFFFFF",
    chatColor: "#000000",
    bannerWidth: 460,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template12",
    isWhiteIcons: true,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: true,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "#7C9883",
  },
  template13: {
    selectedTemplateName: "template13",
    mainColor: "#000000",
    secondaryColor: "#000000",
    descriptionBackground: "#7C9883",
    borderColor: "#7C9883",
    signatureWidth: 420,
    profileImageSize: 160,
    profileImageShape: 50,
    logoSize: 100,
    socialIconSize: 16,
    socialIconShape: 50,
    contactIconShape: 50,
    contactIconSize: 16,
    fontSize: "medium",
    fontFamily: "Arial, sans-serif",
    socialIconText: false,
    socialIconColor: "#FFFFFF",
    addonsColor: "#9F9F9F",
    meetingColor: "#000000",
    chatColor: "#000000",
    bannerWidth: 420,
    meetingWidth: 20,
    meetingChat: {
      meetingText: "Book a meeting",
      meetingImageHref: "",
      chatTextIcon: "",
      chatText: "Schedule free consultation",
      chatTextHref: "",
    },
    selectedTemplate: "template13",
    isWhiteIcons: false,
    iconLinksBlack: { ...iconLinksBlack },
    iconLinksWhite: { ...iconLinksWhite },
    selectedIcons: ["facebook", "x", "instagram"],
    socialLinks: {
      facebook: "https://www.facebook.com",
      x: "https://www.x.com",
      instagram: "https://www.instagram.com",
    },
    isWhiteContactIcons: false,
    contactIconLinksBlack: { ...contactIconLinksBlack },
    contactIconLinksWhite: { ...contactIconLinksWhite },
    selectedContactIcons: ["phone", "mobile", "email", "chat", "meeting"],
    contactIconColor: "transparent",
  },
};
