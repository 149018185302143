import { createRouter, createWebHistory } from "vue-router";
import MainPanel from "@/generator/MainPanel.vue";
import BasicInfo from "@/views/GeneratorBasicInfo.vue";
import Icons from "@/views/GeneratorIcons.vue";
import Options from "@/views/GeneratorOptions.vue";
import Templates from "@/views/GeneratorTemplates.vue";
import Addons from "@/views/GeneratorAddons.vue";
import FeedbackPositive from "@/feedback/FeedbackPositive.vue";
import FeedbackNegative from "@/feedback/FeedbackNegative.vue";
import AdminPanel from "@/generator/AdminPanel.vue";

const routes = [
  {
    path: "/",
    component: MainPanel,
    children: [
      {
        path: "",
        component: Templates,
      },
      {
        path: "/info",
        component: BasicInfo,
      },
      {
        path: "/templates",
        component: Templates,
      },
      {
        path: "/options",
        component: Options,
      },
      {
        path: "/icons",
        component: Icons,
      },
      {
        path: "/addons",
        component: Addons,
      },
    ],
  },
  {
    path: "/preview/:id",
    component: MainPanel,
  },
  {
    path: "/settings/:id",
    component: MainPanel,
  },
  {
    path: "/dev/:id",
    component: MainPanel,
  },
  { path: "/positive", component: FeedbackPositive },
  { path: "/negative", component: FeedbackNegative },
  { path: "/admin", component: AdminPanel },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
