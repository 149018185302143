<template>
  <div v-if="show" class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t('modalSave.saveYourSignature') }}</h2>
        <button class="close-button" @click="$emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            class="close-icon">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="tabs">
        <button :class="{ active: activeTab === 'email' }" @click="switchToEmailTab">
          {{ $t('modalSave.saveToEmail') }}
        </button>
        <button :class="{ active: activeTab === 'gsignature' }" @click="switchToGSignatureTab">
          {{ $t('modalSave.saveAndManageInGsignature') }}
        </button>
      </div>

      <div class="tab-content">
        <!-- Email Tab -->
        <div v-if="activeTab === 'email'">
          <div class="form-container">
            <div v-if="isLoading" class="loader-container">
              <LoaderApp :isLoading="true" />
            </div>
            <div class="link-container" v-else>
              <div v-if="linksGenerated">
                <div class="link-item" v-for="(link, label) in linkDetails" :key="label">
                  <label :for="label + 'Link'">
                    {{ $t('modalSave.linkLabel', { label: label }) }}
                  </label>
                  <div class="link-input-container">
                    <input :value="link" readonly class="link-input" />
                    <div class="icon-container" @mouseenter="showTooltip($t('modalSave.copy'))"
                      @mouseleave="hideTooltip">
                      <button class="copy-button" @click="handleCopyLink(link)">
                        <i class="fas fa-clipboard"></i>
                      </button>
                      <div class="tooltip">{{ tooltip }}</div>
                    </div>
                    <div class="icon-container" @mouseenter="showTooltip($t('modalSave.open'))"
                      @mouseleave="hideTooltip">
                      <button class="open-button" @click="openLinkInNewTab(link)">
                        <i class="fas fa-external-link-alt"></i>
                      </button>
                      <div class="tooltip">{{ tooltip }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <label>
                  {{ $t('modalSave.enterEmailInstruction') }}
                </label>
                <div class="email-input-container">
                  <input v-model="emailComputed" type="email" :placeholder="$t('modalSave.enterEmailPlaceholder')"
                    class="email-input" @keydown.enter="sendEmail" />
                  <button class="action-button" @click="sendEmail">
                    {{ $t('modalSave.sendEmail') }}
                  </button>
                </div>
                <p v-if="emailError" class="error-message">
                  {{ emailError }}
                </p>
                <p class="disclaimer">
                  {{ $t('modalSave.disclaimerText') }}
                  <a href="https://www.gsignature.com/terms-of-service" target="_blank">
                    {{ $t('modalSave.termsOfService') }}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- gSignature Tab -->
        <div v-else-if="activeTab === 'gsignature'">
          <div v-if="showSuccessScreen" class="success-screen">
            <div class="success-container">
              <h2>{{ $t('modalSave.signatureSavedSuccessfully') }}</h2>
              <p>
                {{ $t('modalSave.signatureSavedDesc') }}
              </p>
              <p>
                {{ $t('modalSave.manageSignatureText') }}
              </p>
              <div class="link-gsignature">
                <a :href="customTemplateLink" target="_blank">
                  gSignature
                </a>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="hasAuthToken" class="form-container">
              <div v-if="isLoading" class="loader-container">
                <LoaderApp :isLoading="true" />
              </div>
              <div class="link-container" v-else>
                <label for="domainInput" class="form-label">
                  {{ $t('modalSave.domainNameLabel') }}
                </label>
                <div class="name-input-container">
                  <input v-model="domainName" type="text" :placeholder="$t('modalSave.enterDomainName')"
                    class="name-input" id="domainInput" required readonly />
                </div>
                <label for="adminEmailInput" class="form-label">
                  {{ $t('modalSave.domainAdminEmailLabel') }}
                </label>
                <div class="email-input-container">
                  <input v-model="adminEmail" type="email" :placeholder="$t('modalSave.enterAdminEmail')"
                    class="email-input" id="adminEmailInput" required readonly />
                  <button class="action-button" @click="saveToGSignature">
                    {{ $t('common.save') }}
                  </button>
                </div>
                <p class="info-message">
                  {{ $t('modalSave.clickSaveInfo') }}
                </p>
                <p v-if="generalError" class="error-message">
                  {{ generalError }}
                </p>
              </div>
            </div>
            <div v-else class="form-container">
              <div class="link-container">
                <label>
                  {{ $t('modalSave.authorizationRequired') }}
                </label>
                <div class="action-display">
                  <button class="action-button" @click="redirectToSignUp">
                    {{ $t('modalSave.signUp') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showToast" class="toast">
        {{ toastMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import LoaderApp from "../loader/LoaderView.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import { useRouter } from "vue-router";
import axios from "axios";
import { BACKEND_URL } from "@/environments";

export default {
  props: ["show", "formData", "code", "originalFormData"],
  components: {
    LoaderApp,
  },
  data() {
    return {
      email: "",
      linkName: "",
      originalLinkName: "",
      editLink: "",
      previewLink: "",
      devLink: "",
      linkDetails: {},
      showToast: false,
      toastMessage: "",
      linksGenerated: false,
      emailError: "",
      linkNameError: "",
      isLoading: false,
      tooltip: "",
      activeTab: "email",
      domainName: "",
      name: "",
      adminEmail: "",
      generalError: "",
      saveSuccess: false,
      saveError: false,
      showSuccessScreen: false,
      templateId: "",
    };
  },
  computed: {
    customTemplateLink() {
      return `https://app.gsignature.com/signatures/custom/${this.templateId}`;
    },
    hasAuthToken() {
      return !!sessionStorage.getItem("generatorAuthToken");
    },
    decodedToken() {
      const token = sessionStorage.getItem("generatorAuthToken");
      if (token) {
        try {
          const payload = token.split('.')[1];
          return JSON.parse(atob(payload));
        } catch (e) {
          console.error("Error decoding token:", e);
          return null;
        }
      }
      return null;
    },
    tokenEmail() {
      if (this.hasAuthToken && this.decodedToken) {
        return this.decodedToken.email;
      }
      return null;
    },
    emailComputed: {
      get() {
        return this.email;
      },
      set(newValue) {
        this.email = newValue;
      }
    }
  },
  mounted() {
    if (this.hasAuthToken && this.decodedToken) {
      this.email = this.decodedToken.email;
      this.domainName = this.decodedToken.domain;
      this.adminEmail = this.decodedToken.email;
    }
  },
  setup() {
    const router = useRouter();
    return { basePath: router.options.history.base };
  },
  methods: {
    async generateUniqueName(email) {
      try {
        const response = await axios.get(`${BACKEND_URL}/check-link-name`, {
          params: { email },
        });
        if (response.data.candidate) {
          return response.data.candidate;
        } else {
          throw new Error("Error generating unique name");
        }
      } catch (error) {
        console.error("generateUniqueName error:", error);
        throw error;
      }
    },
    switchToEmailTab() {
      if (this.activeTab === "gsignature") {
        this.$emit("restore-original-form-data");
      }
      this.activeTab = "email";
    },
    switchToGSignatureTab() {
      if (this.activeTab === "email") {
        this.$emit("update-form-data-for-export");
      }
      this.activeTab = "gsignature";
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validateLinkName(name) {
      const re = /^[a-zA-Z0-9.-]+$/;
      return re.test(name);
    },
    async generateLinks() {
      this.isLoading = true;
      try {
        const response = await axios.post(`${BACKEND_URL}/save`, {
          settings: this.formData,
          email: this.email,
          linkName: this.linkName || null,
          originalLinkName: this.originalLinkName || null,
        });
        const data = response.data;
        if (data.error) {
          this.linkNameError = data.error;
          this.linkName = this.originalLinkName;
          this.isLoading = false;
          return;
        }
        const id = data.id;
        const domain = window.location.origin;
        const basePath = this.basePath.endsWith("/")
          ? this.basePath
          : `${this.basePath}/`;
        this.editLink = `${domain}${basePath}settings/${id}`;
        this.previewLink = `${domain}${basePath}preview/${id}`;
        this.devLink = `${domain}${basePath}dev/${id}`;
        this.linksGenerated = true;
        this.linkDetails = {
          Preview: this.previewLink,
          Edit: this.editLink,
          Download: this.devLink,
        };
        this.showToastMessage(`Links generated and sent to email: ${this.email}`);
      } catch (error) {
        console.error("Error generating links:", error);
        this.showToastMessage("Error generating links. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },
    async sendEmail() {
      if (!this.validateEmail(this.email)) {
        this.emailError = "Please enter a valid email address.";
        return;
      } else {
        this.emailError = "";
      }
      try {
        const candidate = await this.generateUniqueName(this.email);
        this.linkName = candidate;
        this.originalLinkName = candidate;
        await this.generateLinks();
        if (this.activeTab === "email") {
          await this.createLead();
        }
      } catch (error) {
        console.error("Error generating link name:", error);
        this.showToastMessage("Error generating link name. Please try again.");
      }
    },
    async createLead() {
      let utmParams = {};
      try {
        const stored = localStorage.getItem("utm_params");
        if (stored) {
          utmParams = JSON.parse(stored);
        }
      } catch (e) {
        console.error("Error parsing UTM params:", e);
      }
      const email = this.email;
      const domain = email.includes('@') ? email.split('@')[1] : "";
      const leadPayload = {
        domainCreated: false,
        type: "Generator",
        domain: domain,
        email: email,
        language: this.$i18n.locale,
        name: this.formData.name,
        phoneNumber:
          this.formData.phoneNumber && this.formData.mobileNumber
            ? `${this.formData.phoneNumber} | ${this.formData.mobileNumber}`
            : this.formData.phoneNumber || this.formData.mobileNumber || "",

        Utm: {
          utm_source: utmParams.utm_source || "",
          utm_medium: utmParams.utm_medium || "",
          utm_campaign: utmParams.utm_campaign || "",
          utm_term: utmParams.utm_term || "",
          utm_content: utmParams.utm_content || ""
        }
      };
      try {
        await axios.post(`${BACKEND_URL}/lead`, leadPayload);
      } catch (error) {
        console.error("Error creating lead:", error);
      }
    },
    openLinkInNewTab(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    handleCopyLink(link) {
      if (!link) return;
      navigator.clipboard.writeText(link).then(() => {
        this.showToastMessage("Link copied to clipboard!");
      });
    },
    showTooltip(text) {
      this.tooltip = text;
    },
    hideTooltip() {
      this.tooltip = "";
    },
    showToastMessage(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    },
    resetModal() {
      this.linkName = "";
      this.originalLinkName = "";
      this.editLink = "";
      this.previewLink = "";
      this.devLink = "";
      this.linkDetails = {};
      this.linksGenerated = false;
      this.showToast = false;
      this.toastMessage = "";
      this.emailError = "";
      this.linkNameError = "";
      this.isLoading = false;
      this.activeTab = "email";
      this.generalError = "";
      this.saveSuccess = false;
      this.saveError = false;
      this.showSuccessScreen = false;
      if (this.hasAuthToken && this.decodedToken) {
        this.email = this.decodedToken.email;
        this.domainName = this.decodedToken.domain;
        this.adminEmail = this.decodedToken.email;
      } else {
        this.email = "";
        this.domainName = "";
        this.adminEmail = "";
      }
    },
    redirectToDevLink() {
      if (this.linksGenerated && this.devLink) {
        window.location.href = this.devLink;
      }
    },
    validateDomainName(domain) {
      const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      return domainRegex.test(domain);
    },
    async saveToGSignature() {
      this.generalError = "";
      this.saveSuccess = false;
      this.saveError = false;
      this.isLoading = true;
      if (!this.domainName) {
        this.generalError = "Domain name is required.";
        this.isLoading = false;
        return;
      } else if (!this.validateDomainName(this.domainName)) {
        this.generalError = "Domain name must be in a valid format (e.g., company.pl).";
        this.isLoading = false;
        return;
      }
      if (!this.validateEmail(this.adminEmail)) {
        this.generalError = "Please enter a valid admin email.";
        this.isLoading = false;
        return;
      }
      try {
        const candidate = await this.generateUniqueName(this.adminEmail);
        this.name = candidate;
        const createDomainPayload = {
          domain: this.domainName,
          adminEmail: this.adminEmail,
        };
        const createDomainResp = await axios.post(
          `${BACKEND_URL}/check-or-create-domain`,
          createDomainPayload
        );
        if (!createDomainResp.data.success) {
          this.generalError = createDomainResp.data.error || "Cannot create domain.";
          this.isLoading = false;
          return;
        }
        const checkAdminResp = await axios.get(`${BACKEND_URL}/check-admin`, {
          params: {
            domain: this.domainName,
            email: this.adminEmail,
          },
        });
        if (!checkAdminResp.data.isAdmin) {
          this.generalError = "The provided email is not an admin for the domain.";
          this.isLoading = false;
          return;
        }
        const saveSignaturePayload = {
          domain: this.domainName,
          name: candidate,
          adminEmail: this.adminEmail,
          signatureCode: this.code,
          phoneLabel: this.originalFormData?.phoneIcon?.trim()
            ? `<td style="padding: 3px; border-radius:${this.originalFormData?.contactIconShape || ''}%; background-color: ${this.originalFormData?.contactIconColor || ''}"><img width="16" height="16" alt="phoneLabel" src="${this.originalFormData?.phoneIcon}" style="display: block;"></td>`
            : this.originalFormData?.phoneLabel || "",
          mobileLabel: this.originalFormData?.mobileIcon?.trim()
            ? `<td style="padding: 3px; border-radius:${this.originalFormData?.contactIconShape || ''}%; background-color: ${this.originalFormData?.contactIconColor || ''}"><img width="16" height="16" alt="mobileLabel" src="${this.originalFormData?.mobileIcon}" style="display: block;"></td>`
            : this.originalFormData?.mobileLabel || "",
          emailLabel: this.originalFormData?.emailIcon?.trim()
            ? `<td style="padding: 3px; border-radius:${this.originalFormData?.contactIconShape || ''}%; background-color: ${this.originalFormData?.contactIconColor || ''}"><img width="16" height="16" alt="emailLabel" src="${this.originalFormData?.emailIcon}" style="display: block;"></td>`
            : this.originalFormData?.emailLabel || "",
        };
        const saveSignatureResp = await axios.post(
          `${BACKEND_URL}/save-signature`,
          saveSignaturePayload
        );
        if (!saveSignatureResp.data.success) {
          this.generalError = saveSignatureResp.data.error || "Error saving signature.";
          this.isLoading = false;
          return;
        }
        this.templateId = saveSignatureResp.data.templateId;
        this.saveSuccess = true;
        this.showSuccessScreen = true;
        this.linkName = candidate;
        this.email = this.adminEmail;
        await this.sendEmail();
      } catch (error) {
        console.error(error);
        this.generalError = error.message || "Unknown error occurred.";
        this.saveError = true;
      } finally {
        this.isLoading = false;
      }
    },
    redirectToSignUp() {
      window.location.href = "https://www.gsignature.com/sign-up";
    },
  },
  watch: {
    show(newVal) {
      if (!newVal) {
        this.$emit("restore-original-form-data");
        this.resetModal();
      }
      if (newVal) {
        const storedEmail = sessionStorage.getItem("brandEmail");
        if (storedEmail && !this.email) {
          this.email = storedEmail;
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
}

.info-message {
  background-color: #e2e2e2;
  border-left: 4px solid #ff6a00;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.tabs {
  display: flex;
  gap: 10px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.tabs button.active {
  background-color: #ff6a00;
  color: #fff;
}

.tab-content {
  margin-top: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.link-container {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.link-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.link-item label {
  margin-bottom: 5px;
  font-weight: bold;
}

.link-gsignature {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
}

.link-gsignature a {
  font-size: 24px;
}

.link-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.link-input {
  flex: 1;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.link-input:focus,
.email-input:focus,
.name-input:focus {
  border-color: #333;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  transition: transform 0.3s ease;
}

.copy-button:hover {
  transform: scale(1.2);
  color: #007bff;
}

.copy-button i,
.open-button i {
  color: #555;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 90%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.icon-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.email-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.email-input {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  margin-right: 10px;
  padding: 12px;
  flex-grow: 1;
}

.name-input-container {
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.name-input {
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 12px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.name-input:focus {
  border-color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.success-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.success-container {
  padding: 20px;
  border-radius: 10px;
}

.success-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.success-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.action-display {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.open-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease;
  margin-left: 5px;
}

.open-button:hover {
  transform: scale(1.2);
}

.dark-mode .copy-button i,
.dark-mode .open-button i {
  color: #bbb;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.dark-mode .modal-content {
  background: linear-gradient(135deg, #2e2e2e, #4a4a4a);
  color: #ddd;
}

.dark-mode .link-input {
  border-color: #555;
  background-color: #333;
  color: #ddd;
}

.dark-mode .email-input,
.dark-mode .name-input {
  border-color: #555;
  background-color: #333;
  color: #ddd;
}

.dark-mode .action-button {
  background-color: #555;
  color: #ddd;
}

.dark-mode .action-button:hover {
  background-color: #666;
}

.disclaimer {
  font-size: 12px;
  color: #666;
  margin-top: 15px;
  text-align: center;
}

.disclaimer a {
  color: #007bff;
  text-decoration: underline;
}
</style>
