<template>
  <div ref="previewContainer" tabindex="0" class="no-select">
    <component :is="currentTemplate" v-bind="$props" />
  </div>
</template>

<script>
import Template1 from "@/templates/TemplateOne.vue";
import Template2 from "@/templates/TemplateTwo.vue";
import Template3 from "@/templates/TemplateThree.vue";
import Template4 from "@/templates/TemplateFour.vue";
import Template5 from "@/templates/TemplateFive.vue";
import Template6 from "@/templates/TemplateSix.vue";
import Template7 from "@/templates/TemplateSeven.vue";
import Template8 from "@/templates/TemplateEight.vue";
import Template9 from "@/templates/TemplateNine.vue";
import Template10 from "@/templates/TemplateTen.vue";
import Template11 from "@/templates/TemplateEleven.vue";
import Template12 from "@/templates/TemplateTwelve.vue";
import Template13 from "@/templates/TemplateThirteen.vue";

export default {
  props: {
    name: String,
    jobTitle: String,
    companyName: String,
    companyAddress: String,
    phoneNumber: String,
    phoneLabel: String,
    mobileNumber: String,
    mobileLabel: String,
    emailAddress: String,
    emailLabel: String,
    mainColor: String,
    secondaryColor: String,
    selectedContactIcons: Array,
    contactIconLinksBlack: Object,
    contactIconLinksWhite: Object,
    contactIconColor: String,
    contactIconSize: Number,
    contactIconShape: Number,
    isWhiteContactIcons: Boolean,
    socialIconColor: String,
    socialIconSize: Number,
    socialIconShape: Number,
    selectedIcons: Array,
    socialIconText: Boolean,
    socialLinks: Object,
    iconLinks: Object,
    customIcons: {
      type: Array,
      default: () => [],
    },
    isWhiteIcons: Boolean,
    profileImage: String,
    profileImageHref: String,
    showProfileImage: Boolean,
    profileImageSize: Number,
    profileImageShape: Number,
    logoImage: String,
    logoImageHref: String,
    showLogo: Boolean,
    descriptionBackground: String,
    borderColor: String,
    signatureWidth: Number,
    disclaimerWidth: Boolean,
    logoSize: Number,
    fontFamily: String,
    fontSize: String,
    additionalFields: Array,
    template: String,
    addonsColor: String,
    meetingColor: String,
    chatColor: String,
    plannedAbsenceColor: String,
    plannedAbsenceTextColor: String,
    bannerWidth: Number,
    meetingWidth: Number,
    feedback: Object,
    link: {
      type: Object,
      default: () => ({}),
    },
    banner: {
      type: Object,
      default: () => ({}),
    },
    disclaimer: {
      type: String,
      default: "",
    },
    plannedAbsence: {
      type: Object,
      default: () => ({}),
    },
    meetingChat: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentTemplate() {
      switch (this.template) {
        case "template1":
          return Template1;
        case "template2":
          return Template2;
        case "template3":
          return Template3;
        case "template4":
          return Template4;
        case "template5":
          return Template5;
        case "template6":
          return Template6;
        case "template7":
          return Template7;
        case "template8":
          return Template8;
        case "template9":
          return Template9;
        case "template10":
          return Template10;
        case "template11":
          return Template11;
        case "template12":
          return Template12;
        case "template13":
          return Template13;
        default:
          return Template1;
      }
    },
  },
  mounted() {
    this.$refs.previewContainer.addEventListener("contextmenu", this.disableRightClick);
    this.$refs.previewContainer.addEventListener("keydown", this.disableCopyShortcut);
  },
  beforeUnmount() {
    this.$refs.previewContainer.removeEventListener("contextmenu", this.disableRightClick);
    this.$refs.previewContainer.removeEventListener("keydown", this.disableCopyShortcut);
  },
  methods: {
    disableRightClick(event) {
      event.preventDefault();
    },
    disableCopyShortcut(event) {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "x" || event.key === "a")
      ) {
        event.preventDefault();
      }
    },
    convertRgbToHex(color) {
      if (!color || !color.startsWith("rgb")) return color;
      const rgb = color.match(/\d+/g);
      if (!rgb) return color;
      const hex = rgb.map((num) => parseInt(num, 10).toString(16).padStart(2, "0")).join("");
      return `#${hex}`;
    },
    processHtml(html) {
      return html.replace(/rgb\((\d+), (\d+), (\d+)\)/g, (_, r, g, b) => {
        return this.convertRgbToHex(`rgb(${r}, ${g}, ${b})`);
      });
    },
  },
};
</script>

<style scoped>
.no-select,
.no-select * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
