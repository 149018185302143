<template>
  <table cellspacing="0" cellpadding="0" border="0" :width="signatureWidth" :style="{ fontFamily: fontFamily }">
    <tr>
      <td v-if="showLogo || (Array.isArray(icons) && icons.length > 0)" style="
          padding: 20px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        " :style="{ backgroundColor: descriptionBackground }">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td style="padding-bottom: 10px" align="center" v-if="showLogo">
              <a v-if="logoImageHref" :href="formatHref(logoImageHref)" target="_blank">
                <img :src="logoImage" alt="Logo" :width="logoSize"
                  :style="{ width: `${logoSize}px`, display: 'block' }" />
              </a>
              <img v-else :src="logoImage" alt="Logo" :width="logoSize"
                :style="{ width: `${logoSize}px`, display: 'block' }" />
            </td>
          </tr>
          <tr v-if="icons && icons.length > 0">
            <td style="padding-top: 10px">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td v-for="(icon, index) in icons" :key="icon" :style="{
                    'padding-right': index !== icons.length - 1 ? '15px' : '0',
                  }">
                    <a v-if="socialLinks[icon]" :href="formatHref(socialLinks[icon])" target="_blank" :style="{
                      display: 'inline-block',
                      'line-height': '0',
                      'text-decoration': 'none',
                    }">
                      <table cellspacing="0" cellpadding="0" border="0">
                        <tr>
                          <td :style="{
                            padding: '7px',
                            'border-radius': `${socialIconShape}%`,
                            backgroundColor: socialIconColor,
                          }">
                            <img :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize"
                              style="display: block" />
                          </td>
                          <td v-if="socialIconText" style="padding-left: 5px; vertical-align: middle">
                            <span :style="{
                              fontFamily: fontFamily,
                              fontSize: `${computedFontSize}px`,
                              color: secondaryColor,
                            }">{{ icon }}</span>
                          </td>
                        </tr>
                      </table>
                    </a>
                    <a v-else target="_blank" :style="{
                      display: 'inline-block',
                      'line-height': '0',
                      'text-decoration': 'none',
                    }">
                      <table cellspacing="0" cellpadding="0" border="0">
                        <tr>
                          <td :style="{
                            padding: '7px',
                            'border-radius': `${socialIconShape}%`,
                            backgroundColor: socialIconColor,
                          }">
                            <img :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize"
                              style="display: block" />
                          </td>
                          <td v-if="socialIconText" style="padding-left: 5px; vertical-align: middle">
                            <span :style="{
                              fontFamily: fontFamily,
                              fontSize: `${computedFontSize}px`,
                              color: secondaryColor,
                            }">{{ icon }}</span>
                          </td>
                        </tr>
                      </table>
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
      <td v-if="
        name ||
        jobTitle ||
        mobileNumber ||
        emailAddress ||
        (Array.isArray(additionalFields) && additionalFields.length > 0) ||
        (feedback && feedback.question) ||
        companyName ||
        companyAddress
      " style="padding-left: 20px">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr v-if="name || jobTitle">
            <td style="padding-bottom: 10px">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr v-if="name">
                  <td>
                    <span :style="{
                      fontFamily: fontFamily,
                      color: mainColor,
                      fontSize: `${computedFontSize + 3}px`,
                    }"><strong>{{ name }}</strong></span>
                  </td>
                </tr>
                <tr v-if="jobTitle">
                  <td>
                    <span :style="{
                      fontFamily: fontFamily,
                      color: mainColor,
                      fontSize: `${computedFontSize}px`,
                    }">{{ jobTitle }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="mobileNumber">
            <td style="padding: 2px 0;">
              <table>
                <tr>
                  <td v-if="selectedContactIcons.includes('mobile') && mobileNumber" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img v-if="getContactIcon('mobile')" :src="getContactIcon('mobile')" :width="contactIconSize"
                      :height="contactIconSize" alt="Mobile" style="display: block;" />
                  </td>
                  <td v-else-if="mobileLabel">
                    <span
                      :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{
                        mobileLabel + ' ' }}</strong></span>
                  </td>
                  <td :style="{ 'padding-left': selectedContactIcons.includes('mobile') || mobileLabel ? '4px' : '0' }">
                    <div v-if="isPlaceholder(mobileNumber, 'mobile')" :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: secondaryColor,
                      verticalAlign: middle
                    }">
                      {{ mobileNumber }}
                    </div>
                    <a v-else :href="`tel:${formatMobileNumber(mobileNumber)}`" target="_blank" :style="{
                      display: 'block',
                      'line-height': `${computedFontSize}px`,
                      'text-decoration': 'none',
                    }">
                      <span :style="{
                        fontFamily: fontFamily,
                        fontSize: `${computedFontSize}px`,
                        color: secondaryColor,
                        verticalAlign: middle
                      }">{{ mobileNumber }}</span>
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="phoneNumber">
            <td style="padding: 2px 0;">
              <table>
                <tr>
                  <td v-if="selectedContactIcons.includes('phone') && phoneNumber" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img :src="getContactIcon('phone')" :width="contactIconSize" :height="contactIconSize" alt="Phone"
                      style="display: block;" />
                  </td>
                  <td v-else-if="phoneLabel">
                    <span
                      :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{
                        phoneLabel + ' ' }}</strong></span>
                  </td>
                  <td :style="{ 'padding-left': selectedContactIcons.includes('phone') || phoneLabel ? '4px' : '0' }">
                    <div v-if="isPlaceholder(phoneNumber, 'phone')" :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: secondaryColor,
                      verticalAlign: middle
                    }">
                      {{ phoneNumber }}
                    </div>
                    <a v-else :href="`tel:${formatPhoneNumber(phoneNumber)}`" target="_blank" :style="{
                      display: 'block',
                      'line-height': `${computedFontSize}px`,
                      'text-decoration': 'none',
                    }">
                      <span :style="{
                        fontFamily: fontFamily,
                        fontSize: `${computedFontSize}px`,
                        color: secondaryColor,
                        verticalAlign: middle
                      }">{{ phoneNumber }}</span>
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="emailAddress">
            <td style="padding: 2px 0;">
              <table>
                <tr>
                  <td v-if="selectedContactIcons.includes('email') && emailAddress" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img v-if="getContactIcon('email')" :src="getContactIcon('email')" :width="contactIconSize"
                      :height="contactIconSize" alt="Email" style="display: block;" />
                  </td>
                  <td v-else-if="emailLabel">
                    <span
                      :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{
                        emailLabel + ' ' }}</strong></span>
                  </td>
                  <td :style="{
                    'padding-left': selectedContactIcons.includes('email') || emailLabel ? '4px' : '0'
                  }">
                    <div v-if="isPlaceholder(emailAddress, 'email')" :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: secondaryColor,
                      verticalAlign: middle
                    }">
                      {{ emailAddress }}
                    </div>
                    <a v-else :href="`mailto:${emailAddress}`" target="_blank" :style="{
                      display: 'block',
                      'line-height': `${computedFontSize}px`,
                      'text-decoration': 'none',
                    }">
                      <span :style="{
                        fontFamily: fontFamily,
                        fontSize: `${computedFontSize}px`,
                        color: secondaryColor,
                        verticalAlign: middle
                      }">{{ emailAddress }}</span>
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-for="(field, index) in additionalFields" :key="index">
            <td style="padding: 2px 0;">
              <table v-if="field.href">
                <tr>
                  <td v-if="field.icon" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img v-if="field.icon" :src="field.icon" :width="contactIconSize" :height="contactIconSize"
                      :alt="field.label" style="display: block;" />
                  </td>
                  <td v-else>
                    <span :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: mainColor
                    }"><strong>{{ field.label + ' ' }}</strong></span>
                  </td>
                  <td :style="{ 'padding-left': field.icon || field.label ? '4px' : '0' }">
                    <a v-if="field.href" :href="formatHref(field.href, field.hrefType)" target="_blank" :style="{
                      display: 'block',
                      'line-height': `${computedFontSize}px`,
                      'text-decoration': 'none',
                    }">
                      <span :style="{
                        fontFamily: fontFamily,
                        fontSize: `${computedFontSize}px`,
                        color: secondaryColor,
                        verticalAlign: middle
                      }">{{ field.value }}</span>
                    </a>
                  </td>
                </tr>
              </table>
              <table v-else>
                <tr>
                  <td v-if="field.icon" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img v-if="field.icon" :src="field.icon" :width="contactIconSize" :height="contactIconSize"
                      :alt="field.label" style="display: block;" />
                  </td>
                  <td v-else>
                    <span :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: mainColor
                    }"><strong>{{ field.label + ' ' }}</strong></span>
                  </td>
                  <td :style="{ 'padding-left': field.icon || field.label ? '4px' : '0' }">
                    <span :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: secondaryColor,
                      verticalAlign: middle
                    }">{{ field.value }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="feedback && feedback.question">
            <td style="padding-top: 5px">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <p :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">
                      {{ feedback.question }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td align="center" style="padding-top: 5px">
                    <a v-if="feedback.likeLink" :href="formatHref(feedback.likeLink)" target="_blank"
                      :style="{ display: 'block', 'text-decoration': 'none' }">
                      <img :src="feedback.likeIcon" width="24" height="24" alt="Like"
                        style="padding-right: 5px; vertical-align: middle;" />
                    </a>
                    <img v-else :src="feedback.likeIcon" width="24" height="24" alt="Like"
                      style="padding-right: 5px; vertical-align: middle;" />
                    <a v-if="feedback.dislikeLink" :href="formatHref(feedback.dislikeLink)" target="_blank"
                      :style="{ display: 'block', 'text-decoration': 'none' }">
                      <img :src="feedback.dislikeIcon" width="24" height="24" alt="Dislike"
                        style="vertical-align: middle;" />
                    </a>
                    <img v-else :src="feedback.dislikeIcon" width="24" height="24" alt="Dislike"
                      style="vertical-align: middle;" />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="companyName || companyAddress">
            <td align="left" style="padding-top: 5px;">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr v-if="companyName">
                  <td>
                    <span :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: '#000000',
                    }"><strong>{{ companyName }}</strong></span>
                  </td>
                </tr>
                <tr v-if="companyAddress">
                  <td>
                    <span :style="{
                      fontFamily: fontFamily,
                      fontSize: `${computedFontSize}px`,
                      color: '#000000',
                    }">{{ companyAddress }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="meetingChat && (meetingChat.meetingText || meetingChat.chatText) || meetingChat && meetingChat.chatText"
    cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
    <tr>
      <td style="padding-top: 10px;">
        <table>
          <tr>
            <td v-if="meetingChat && (meetingChat.meetingText || meetingChat.chatText)">
              <table v-if="meetingChat.meetingImageHref" cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <a :href="formatHref(meetingChat.meetingImageHref)" target="_blank"
                      :style="{ display: 'block', 'line-height': `${computedFontSize}px`, 'text-decoration': 'none' }">
                      <table cellspacing="0" cellpadding="0" border="0">
                        <tr>
                          <td :style="{
                            backgroundColor: contactIconColor,
                            padding: (contactIconColor && contactIconColor !== 'transparent') ? '12px 14px' : '',
                            borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? '8px' : ''
                          }">
                            <table cellspacing="0" cellpadding="0" border="0" style="line-height: 0">
                              <tr>
                                <td v-if="selectedContactIcons.includes('meeting')">
                                  <img :src="getContactIcon('meeting')" alt="Meeting" :width="contactIconSize"
                                    :height="contactIconSize" style="vertical-align: middle;" />
                                </td>
                                <td style="padding: 3px 3px 3px 10px;">
                                  <span :style="{
                                    fontFamily: fontFamily,
                                    color: meetingColor,
                                    fontSize: `${computedFontSize}px`,
                                    verticalAlign: middle
                                  }"><strong>{{ meetingChat.meetingText }}</strong></span>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </a>
                  </td>
                </tr>
              </table>
              <table v-else cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <table cellspacing="0" cellpadding="0" border="0" style="line-height: 0">
                      <tr>
                        <td :style="{
                          backgroundColor: contactIconColor,
                          padding: (contactIconColor && contactIconColor !== 'transparent') ? '12px 14px' : '',
                          borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? '8px' : ''
                        }">
                          <table cellspacing="0" cellpadding="0" border="0" style="line-height: 0">
                            <tr>
                              <td>
                                <img v-if="selectedContactIcons.includes('meeting')" :src="getContactIcon('meeting')"
                                  alt="Meeting" :width="contactIconSize" :height="contactIconSize"
                                  style="vertical-align: middle;" />
                              </td>
                              <td style="padding: 5px 5px 5px 10px;">
                                <span :style="{
                                  fontFamily: fontFamily,
                                  color: meetingColor,
                                  fontSize: `${computedFontSize}px`,
                                  verticalAlign: middle
                                }"><strong>{{ meetingChat.meetingText }}</strong></span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td v-if="meetingChat && meetingChat.chatText"
              :style="{ 'padding-left': meetingChat && (meetingChat.meetingText || meetingChat.chatText) ? '15px' : '0' }">
              <table>
                <tr>
                  <td v-if="selectedContactIcons.includes('chat') && meetingChat.chatText" :style="{
                    backgroundColor: contactIconColor,
                    padding: (contactIconColor && contactIconColor !== 'transparent') ? '6px' : '',
                    borderRadius: (contactIconColor && contactIconColor !== 'transparent') ? `${contactIconShape}%` : ''
                  }">
                    <img :src="getContactIcon('chat')" :width="contactIconSize" :height="contactIconSize" alt="Chat"
                      style="display: block;" />
                  </td>
                  <td v-if="meetingChat.chatTextHref"
                    :style="{ 'padding-left': selectedContactIcons.includes('chat') ? '5px' : '0' }">
                    <a :href="formatHref(meetingChat.chatTextHref)" target="_blank" :style="{
                      display: 'block',
                      'line-height': `${computedFontSize}px`,
                      'text-decoration': 'none',
                    }">
                      <span :style="{
                        fontFamily: fontFamily,
                        color: chatColor,
                        fontSize: `${computedFontSize}px`,
                        verticalAlign: middle
                      }"><strong>{{ meetingChat.chatText }}</strong></span>
                    </a>
                  </td>
                  <td v-else :style="{ 'padding-left': selectedContactIcons.includes('chat') ? '5px' : '0' }">
                    <span :style="{
                      fontFamily: fontFamily,
                      color: chatColor,
                      fontSize: `${computedFontSize}px`,
                      verticalAlign: middle
                    }"><strong>{{ meetingChat.chatText }}</strong></span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="plannedAbsence && (plannedAbsence.text || plannedAbsence.date)" cellspacing="0" cellpadding="0"
    border="0" :width="signatureWidth">
    <tr>
      <td :style="{ 'padding-top': '20px' }">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td :style="{
              padding: '8px 16px',
              'border-radius': '8px',
              width: signatureWidth + 'px',
              backgroundColor: plannedAbsenceColor
            }">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <span v-if="plannedAbsence.text" :style="{
                      fontFamily: fontFamily,
                      color: plannedAbsenceTextColor,
                      fontSize: `${computedFontSize}px`,
                      width: signatureWidth + 'px'
                    }">{{ plannedAbsence.text }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-if="plannedAbsence.date" :style="{
                      fontFamily: fontFamily,
                      color: plannedAbsenceTextColor,
                      fontSize: `${computedFontSize}px`,
                      width: signatureWidth + 'px'
                    }">{{ plannedAbsence.date }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="link && link.name" :width="signatureWidth" cellspacing="0" cellpadding="0" border="0">
    <tr>
      <td style="padding-top: 10px">
        <a v-if="link.url" :href="formatHref(link.url)" target="_blank" :style="{
          display: 'block',
          'line-height': `${computedFontSize}px`,
          'text-decoration': 'none',
        }">
          <span :style="{ fontFamily: fontFamily, color: addonsColor }">{{ link.name }}</span>
        </a>
        <span v-else :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">{{
          link.name }}</span>
      </td>
    </tr>
  </table>
  <table v-if="banner && banner.url" cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
    <tr>
      <td style="padding-top: 20px">
        <a v-if="banner.href" :href="formatHref(banner.href)" target="_blank" :style="{
          display: 'block',
          'line-height': `${computedFontSize}px`,
          'text-decoration': 'none',
        }">
          <img :src="banner.url" alt="Banner" :width="bannerWidth" />
        </a>
        <img v-else :src="banner.url" alt="Banner" :width="bannerWidth" />
      </td>
    </tr>
  </table>
  <table v-if="disclaimer" cellspacing="0" cellpadding="0" border="0" :width="tableWidth">
    <tr>
      <td style="padding-top: 3px">
        <p v-html="disclaimer"
          :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize - 3}px` }"></p>
      </td>
    </tr>
  </table>
  <table v-if="!hasAuthToken" style="border-collapse:collapse;" cellspacing="0" cellpadding="0" border="0">
    <tr>
      <td style="padding-top: 5px; line-height: 20px;"><span
          style="font-family: Arial, sans-serif; color: #333333; font-size: 10px;">Email signature created by</span>
      </td>
      <td style="padding-left: 3px; padding-top: 5px;"><a target="_blank" style="text-decoration: none;"
          href="https://gsignature.com/?utm_source=watermark_generator&utm_medium=generator&utm_campaign=watermark_generator"><img
            src="https://storage.googleapis.com/prod_gsignature_user_images/gsignature.com/media/images/2025-02-12T15:24:57.884Z_0f1be6ca-3f97-4e51-973c-e9a305ec541b"
            width="20" alt="gSignature" style="vertical-align: middle; width: 20px;"></a></td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    name: String,
    jobTitle: String,
    companyName: String,
    companyAddress: String,
    phoneNumber: String,
    phoneLabel: String,
    mobileNumber: String,
    mobileLabel: String,
    emailAddress: String,
    emailLabel: String,
    mainColor: String,
    secondaryColor: String,
    selectedContactIcons: Array,
    contactIconLinksBlack: Object,
    contactIconLinksWhite: Object,
    contactIconColor: String,
    contactIconSize: Number,
    contactIconShape: Number,
    isWhiteContactIcons: Boolean,
    socialIconColor: String,
    socialIconSize: Number,
    socialIconShape: Number,
    selectedIcons: Array,
    socialIconText: Boolean,
    socialLinks: Object,
    iconLinks: Object,
    customIcons: {
      type: Array,
      default: () => [],
    },
    isWhiteIcons: Boolean,
    profileImage: String,
    profileImageHref: String,
    showProfileImage: Boolean,
    profileImageSize: Number,
    profileImageShape: Number,
    logoImage: String,
    logoImageHref: String,
    showLogo: Boolean,
    descriptionBackground: String,
    borderColor: String,
    signatureWidth: Number,
    disclaimerWidth: Boolean,
    logoSize: Number,
    fontFamily: String,
    fontSize: String,
    additionalFields: Array,
    template: String,
    addonsColor: String,
    meetingColor: String,
    chatColor: String,
    plannedAbsenceColor: String,
    plannedAbsenceTextColor: String,
    bannerWidth: Number,
    meetingWidth: Number,
    feedback: Object,
    link: {
      type: Object,
      default: () => ({}),
    },
    banner: {
      type: Object,
      default: () => ({}),
    },
    disclaimer: {
      type: String,
      default: "",
    },
    plannedAbsence: {
      type: Object,
      default: () => ({}),
    },
    meetingChat: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tableWidth() {
      return this.disclaimerWidth ? "100%" : `${this.signatureWidth}`;
    },
    icons() {
      return this.selectedIcons;
    },
    computedFontSize() {
      switch (this.fontSize) {
        case "small":
          return 12;
        case "medium":
          return 14;
        case "large":
          return 16;
        default:
          return 14;
      }
    },
    hasAuthToken() {
      return !!sessionStorage.getItem("generatorAuthToken");
    },
  },
  methods: {
    getContactIcon(type) {
      if (this.isWhiteContactIcons) {
        return this.contactIconLinksWhite[type] || "";
      } else {
        return this.contactIconLinksBlack[type] || "";
      }
    },
    getIconPath(iconName) {
      if (this.iconLinks && this.iconLinks[iconName]) {
        return this.iconLinks[iconName];
      }
      const customIcon = this.customIcons.find(
        (icon) => icon.name === iconName
      );
      return customIcon ? customIcon.url : "";
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/\s+/g, "");
    },
    formatMobileNumber(mobileNumber) {
      return mobileNumber.replace(/\s+/g, "");
    },
    formatHref(href, hrefType) {
      if (!href) return "";
      switch (hrefType) {
        case "tel":
          return `tel:${href}`;
        case "mailto":
          return `mailto:${href}`;
        default:
          if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`;
          }
          return href;
      }
    },
    isPlaceholder(value, type) {
      if (type === "phone") {
        return value.includes("${labelPhoneNumber}");
      } else if (type === "mobile") {
        return value.includes("${labelMobileNumber}");
      } else if (type === "email") {
        return value.includes("${labelUserEmail}");
      }
      return false;
    },
  },
};
</script>
