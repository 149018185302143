<template>
  <div>
    <div class="header">
      <h2>{{ $t('generatorBasicInfo.title') }}</h2>
    </div>

    <!-- Profile Image Upload -->
    <div class="form-image">
      <label for="profileImage">
        {{ $t('generatorBasicInfo.profileImage') }}:
      </label>
      <div class="image-upload">
        <div class="image-preview" v-if="profileImageView">
          <img :src="profileImageView" alt="Profile Image" />
          <button @click="removeImage" class="btn-remove">
            {{ $t('common.remove') }}
          </button>
        </div>
        <div class="image-file">
          <label for="profileImage" class="custom-file-upload">
            <i class="fas fa-upload"></i>
            {{ $t('generatorBasicInfo.uploadProfileImage') }}
          </label>
          <input type="file" id="profileImage" @change="uploadProfileImage" class="input-file" />
          <input type="text" id="profileImageURL" v-model="profileImageURL" @input="updateProfileImageURL"
            :placeholder="$t('generatorBasicInfo.enterProfileImageUrl')" />
        </div>
      </div>
      <div class="form-group">
        <input type="text" id="profileImageHref" v-model="profileImageHref" @input="updateProfileImageHref"
          :placeholder="$t('generatorBasicInfo.enterProfileImageHref')" />
      </div>
    </div>

    <!-- Logo Image Upload -->
    <div class="form-image">
      <label for="logoImage">
        {{ $t('generatorBasicInfo.logo') }}:
      </label>
      <div class="image-upload">
        <div class="image-preview" v-if="localLogoImage">
          <img :src="localLogoImage" alt="Logo" />
          <button @click="removeLogo" class="btn-remove">
            {{ $t('common.remove') }}
          </button>
        </div>
        <div class="image-file">
          <label for="logoImage" class="custom-file-upload">
            <i class="fas fa-upload"></i>
            {{ $t('generatorBasicInfo.uploadLogo') }}
          </label>
          <input type="file" id="logoImage" @change="uploadLogoImage" class="input-file" />
          <input type="text" id="logoImageURL" v-model="logoImageURL" @input="updateLogoImageURL"
            :placeholder="$t('generatorBasicInfo.enterLogoImageUrl')" />
        </div>
      </div>
      <div class="form-group">
        <input type="text" id="logoImageHref" v-model="logoImageHref" @input="updateLogoImageHref"
          :placeholder="$t('generatorBasicInfo.enterLogoImageHref')" />
      </div>
    </div>

    <!-- Form -->
    <form @submit.prevent>
      <!-- Standard Fields -->
      <div class="form-group">
        <label for="name">{{ $t('generatorBasicInfo.name') }}:</label>
        <input type="text" id="name" v-model="localName" @input="updateField('name', localName)"
          :placeholder="$t('generatorBasicInfo.enterName')" />
      </div>
      <div class="form-group">
        <label for="jobTitle">{{ $t('generatorBasicInfo.jobTitle') }}:</label>
        <input type="text" id="jobTitle" v-model="localJobTitle" @input="updateField('jobTitle', localJobTitle)"
          :placeholder="$t('generatorBasicInfo.enterJobTitle')" />
      </div>
      <div class="form-group">
        <label for="companyName">{{ $t('generatorBasicInfo.company') }}:</label>
        <input type="text" id="companyName" v-model="localCompanyName"
          @input="updateField('companyName', localCompanyName)"
          :placeholder="$t('generatorBasicInfo.enterCompanyName')" />
        <input type="text" id="companyAddress" v-model="localCompanyAddress"
          @input="updateField('companyAddress', localCompanyAddress)"
          :placeholder="$t('generatorBasicInfo.enterCompanyAddress')" />
      </div>
      <div class="form-group">
        <label for="phoneNumber">
          {{ $t('generatorBasicInfo.phoneNumber') }}:
        </label>
        <input type="tel" id="phoneNumber" v-model="localPhoneNumber"
          @input="updateField('phoneNumber', localPhoneNumber)" :placeholder="$t('generatorBasicInfo.enterPhoneNumber')"
          inputmode="numeric" />
        <div class="input-group">
          <input type="text" id="phoneLabel" v-model="localPhoneLabel"
            @input="updateField('phoneLabel', localPhoneLabel)"
            :placeholder="$t('generatorBasicInfo.enterPhoneLabel')" />
        </div>
      </div>
      <div class="form-group">
        <label for="mobileNumber">
          {{ $t('generatorBasicInfo.mobileNumber') }}:
        </label>
        <input type="tel" id="mobileNumber" v-model="localMobileNumber"
          @input="updateField('mobileNumber', localMobileNumber)"
          :placeholder="$t('generatorBasicInfo.enterMobileNumber')" inputmode="numeric" />
        <div class="input-group">
          <input type="text" v-model="localMobileLabel" @input="updateField('mobileLabel', localMobileLabel)"
            :placeholder="$t('generatorBasicInfo.enterMobileLabel')" />
        </div>
      </div>
      <div class="form-group">
        <label for="emailAddress">
          {{ $t('generatorBasicInfo.emailAddress') }}:
        </label>
        <input type="email" id="emailAddress" v-model="localEmailAddress"
          @input="updateField('emailAddress', localEmailAddress)"
          :placeholder="$t('generatorBasicInfo.enterEmailAddress')" />
        <div class="input-group">
          <input type="text" v-model="localEmailLabel" @input="updateField('emailLabel', localEmailLabel)"
            :placeholder="$t('generatorBasicInfo.enterEmailLabel')" />
        </div>
      </div>

      <!-- Additional Fields -->
      <div class="additional-fields">
        <div class="form-group" v-for="(additionalField, index) in localAdditionalFields" :key="index">
          <div class="input-additional-fields">
            <label :for="'additionalField-' + index">
              {{
                additionalField.title
                  ? additionalField.title + ':'
                  : ''
              }}
            </label>
            <div class="form-additional-fields">
              <div class="input-additional-fields">
                <div class="input-group">
                  <input type="text" :id="'additionalField-' + index" v-model="additionalField.value"
                    @input="updateAdditionalField(index, additionalField.value)"
                    :placeholder="$t('generatorBasicInfo.enterValue')" />
                  <input type="text" :id="'additionalField-href-' + index" v-model="additionalField.href"
                    :placeholder="getHrefPlaceholder(additionalField.hrefType)"
                    @input="updateAdditionalFieldHref(index, additionalField.href)" />
                </div>
                <div class="input-group">
                  <input type="text" :id="'additionalLabel-' + index" v-model="additionalField.label"
                    @input="updateAdditionalFieldLabel(index, additionalField.label)"
                    :placeholder="$t('generatorBasicInfo.enterLabel')" />
                  <input type="text" v-model="additionalField.icon" @input="updateField('icon', additionalField.icon)"
                    :placeholder="$t('generatorBasicInfo.enterIconUrl')" />
                </div>
              </div>
              <button type="button" class="btn-remove" @click="removeAdditionalField(index)">
                {{ $t('common.remove') }}
              </button>
            </div>
          </div>
        </div>

        <!-- Add Field Button -->
        <button type="button" class="btn-add-field" @click="showModal">
          {{ $t('generatorBasicInfo.addField') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { BACKEND_URL } from "@/environments";

export default {
  props: {
    formData: Object,
    additionalFields: Array,
    logoImage: String,
    mainColor: String,
    secondaryColor: String,
  },
  data() {
    return {
      localName: this.formData.name,
      localJobTitle: this.formData.jobTitle,
      localCompanyName: this.formData.companyName,
      localCompanyAddress: this.formData.companyAddress,
      localPhoneNumber: this.formData.phoneNumber,
      localPhoneLabel: this.formData.phoneLabel,
      localMobileNumber: this.formData.mobileNumber,
      localMobileLabel: this.formData.mobileLabel,
      localEmailAddress: this.formData.emailAddress,
      localEmailLabel: this.formData.emailLabel,
      localAdditionalFields: [...this.additionalFields],
      localLogoImage: this.logoImage,
      profileImageHref: this.formData.profileImageHref,
      logoImageHref: this.formData.logoImageHref,
      profileImageURL: this.formData.profileImage,
      logoImageURL: this.logoImage,
      profileImageView: this.formData.profileImage,
    };
  },
  watch: {
    formData: {
      handler(newValue) {
        this.localName = newValue.name;
        this.localJobTitle = newValue.jobTitle;
        this.localCompanyName = newValue.companyName;
        this.localCompanyAddress = newValue.companyAddress;
        this.localPhoneNumber = newValue.phoneNumber;
        this.localPhoneLabel = newValue.phoneLabel;
        this.localMobileNumber = newValue.mobileNumber;
        this.localMobileLabel = newValue.mobileLabel;
        this.localEmailAddress = newValue.emailAddress;
        this.localEmailLabel = newValue.emailLabel;
        this.profileImageView = newValue.profileImage;
        this.profileImageHref = newValue.profileImageHref;
        this.logoImageHref = newValue.logoImageHref;
      },
      deep: true,
    },
    additionalFields: {
      handler(newValue) {
        this.localAdditionalFields = [...newValue];
      },
      deep: true,
    },
    logoImage(newValue) {
      this.localLogoImage = newValue;
    },
    profileImageHref(newValue) {
      this.profileImageHref = newValue;
    },
    logoImageHref(newValue) {
      this.logoImageHref = newValue;
    },
    profileImage(newValue) {
      this.profileImageView = newValue;
    },
  },
  methods: {
    async uploadProfileImage(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("name", "profileImage");

        try {
          const response = await fetch(`${BACKEND_URL}/images`, {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            return Promise.reject(new Error(`Error: ${response.statusText}`));
          }

          const result = await response.json();
          this.profileImageView = result.imageUrl;
          this.profileImageURL = result.imageUrl;
          this.updateProfileImageURL();
        } catch (error) {
          console.error("Upload failed", error);
        }
      }
    },

    async uploadLogoImage(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("name", "logoImage");

        try {
          const response = await fetch(`${BACKEND_URL}/images`, {
            method: "POST",
            body: formData,
          });

          const result = await response.json();
          this.localLogoImage = result.imageUrl;
          this.logoImageURL = result.imageUrl;
          this.updateLogoImageURL();
        } catch (error) {
          console.error("Upload failed", error);
        }
      }
    },
    updateProfileImageURL() {
      this.profileImageView = this.profileImageURL;
      this.$emit("update-image", this.profileImageURL);
    },
    updateLogoImageURL() {
      this.localLogoImage = this.logoImageURL;
      this.$emit("update-logo-image", this.logoImageURL);
    },
    updateField(fieldName, value) {
      let filteredValue = value;

      if (fieldName === "phoneNumber" || fieldName === "mobileNumber") {
        const validPattern = /[0-9\s+{}a-zA-Z_]/g;
        filteredValue = value.match(validPattern)?.join("") || "";
      }

      this.$emit("update", { [fieldName]: filteredValue });

      if (fieldName === "phoneNumber") {
        this.localPhoneNumber = filteredValue;
      } else if (fieldName === "mobileNumber") {
        this.localMobileNumber = filteredValue;
      }
    },
    removeAdditionalField(index) {
      this.localAdditionalFields.splice(index, 1);
      this.$emit("remove-field", index);
    },
    updateAdditionalField(index, value) {
      this.localAdditionalFields[index].value = value;
      this.$emit("update-field-value", index, value);
    },
    updateAdditionalFieldHref(index, href) {
      this.localAdditionalFields[index].href = href;
      this.$emit("update-field-href", index, href);
    },
    updateAdditionalFieldLabel(index, value) {
      this.localAdditionalFields[index].label = value;
      this.$emit("update-field-label", index, value);
    },
    removeLogo() {
      this.localLogoImage = null;
      this.$emit("update-logo-image", null);
      this.logoImageURL = "";
    },
    removeImage() {
      this.profileImageView = "";
      this.$emit("update-image", "");
      this.profileImageURL = "";
    },
    updateProfileImageHref() {
      this.$emit("update-profile-image-href", this.profileImageHref);
    },
    updateLogoImageHref() {
      this.$emit("update-logo-image-href", this.logoImageHref);
    },
    showModal() {
      this.$emit("show-modal");
    },
    getHrefPlaceholder(hrefType) {
      switch (hrefType) {
        case "tel":
          return "Enter phone number";
        case "mailto":
          return "Enter email address";
        default:
          return "Enter URL";
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
  color: #666;
}

.input-group {
  display: flex;
  gap: 10px;
}

.input-group input {
  flex: 1;
}

.logo-upload img {
  display: block;
  margin-top: 10px;
}

.logo-upload button {
  display: block;
  margin-top: 5px;
  color: #fff;
  background-color: #f00;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.input-file {
  display: none;
}

.custom-file-upload {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #ff6a00;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.custom-file-upload:hover {
  background-color: #c15000;
}

.btn-remove {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-remove:hover {
  background-color: #d32f2f;
}

.btn-add-field {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-add-field:hover {
  background-color: #388e3c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-add-field:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5);
}

.additional-fields {
  margin-top: 1.5rem;
}

.input-additional-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.form-additional-fields {
  display: flex;
  gap: 10px;
}

.form-image {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-upload {
  display: flex;
  align-items: center;
}

.image-preview {
  margin-top: 1rem;
  margin-right: 3rem;
  position: relative;
}

.image-file {
  width: 100%;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-preview .btn-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  transform: translate(50%, -50%);
}

.dark-mode .header h2 {
  color: #ddd;
  border-bottom-color: #555;
}

.dark-mode label {
  color: #ddd;
}

.dark-mode .btn-remove {
  background-color: #e53935;
}

.dark-mode .btn-remove:hover {
  background-color: #c62828;
}

.dark-mode .btn-add-field {
  background-color: #43a047;
}

.dark-mode .btn-add-field:hover {
  background-color: #2e7d32;
}

.dark-mode .image-preview .btn-remove {
  background-color: #e53935;
}

.dark-mode .image-preview .btn-remove:hover {
  background-color: #c62828;
}
</style>
