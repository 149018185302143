<template>
  <div :class="['app', { 'dark-mode': isDarkMode }]">
    <div v-if="isMobile && navOpen" class="overlay" @click="toggleNav"></div>
    <main class="content">
      <div v-if="isLoading" class="loader-container">
        <Loader :isLoading="isLoading" />
      </div>
      <section v-if="!isLoading && !isPreview" class="editor">
        <button class="hamburger" v-if="isMobile" @click="toggleNav">
          <i class="fas fa-bars"></i>
        </button>

        <nav class="top-nav" :class="{
          hidden: isMobile && !navOpen,
          visible: isMobile && navOpen,
        }">
          <router-link to="/templates" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-clipboard-list"></i>
            {{ $t('mainPanel.templates') }}
          </router-link>

          <router-link to="/info" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-info-circle"></i>
            {{ $t('mainPanel.basicInfo') }}
          </router-link>

          <router-link to="/options" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-cogs"></i>
            {{ $t('mainPanel.options') }}
          </router-link>

          <router-link to="/icons" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-share-alt"></i>
            {{ $t('mainPanel.icons') }}
          </router-link>

          <router-link to="/addons" class="nav-tab" exact @click="handleNavClick">
            <i class="fas fa-puzzle-piece"></i>
            {{ $t('mainPanel.addons') }}
          </router-link>

          <button class="nav-tab code-button" @click="showSaveModal">
            <i class="fas fa-code"></i>
            {{ $t('common.save') }}
          </button>

          <button v-if="isDevMode" class="nav-tab code-button" @click="showCodeModal">
            <i class="fas fa-code"></i>
            {{ $t('common.code') }}
          </button>

          <button v-if="isDevMode" class="nav-tab preview-button" @click="navigateTo(generatePreviewLink())">
            <i class="fas fa-eye"></i>
            {{ $t('common.preview') }}
          </button>

          <button v-if="isDevMode" class="nav-tab settings-button" @click="navigateTo(generateEditLink())">
            <i class="fas fa-edit"></i>
            {{ $t('common.settings') }}
          </button>
        </nav>

        <router-view v-if="!isLoading" :formData="formData" :selectedIcons="formData.selectedIcons"
          :socialIconText="socialIconText" :socialLinks="formData.socialLinks" :iconLinks="currentIconLinks"
          :profileImage="formData.profileImage" :additionalFields="formData.additionalFields"
          :customIcons="formData.customIcons" :logoImage="formData.logoImage" :showLogo="showLogo"
          :disclaimerWidth="disclaimerWidth" :logoSize="logoSize" :selectedTemplate="formData.selectedTemplate"
          :link="formData.link" :banner="formData.banner" :disclaimer="formData.disclaimer"
          :plannedAbsence="formData.plannedAbsence" :meetingChat="formData.meetingChat"
          :meetingWidth="formData.meetingWidth" :addonsColor="addonsColor" :meetingColor="meetingColor"
          :chatColor="chatColor" :bannerWidth="bannerWidth" :isWhiteIcons="formData.isWhiteIcons"
          :isWhiteContactIcons="formData.isWhiteContactIcons" :contactIconLinksBlack="formData.contactIconLinksBlack"
          :contactIconLinksWhite="formData.contactIconLinksWhite" :selectedContactIcons="formData.selectedContactIcons"
          @update="updateFormData" @update-image="updateProfileImage" @update-logo-image="updateLogoImage"
          @update-icons="updateSelectedIcons" @add-custom-icon="addCustomIcon" @update-links="updateSocialLinks"
          @textColorChange="updateTextColor" @mainColorChange="updateMainColor"
          @secondaryColorChange="updateSecondaryColor" @descriptionBackgroundChange="updateDescriptionBackground"
          @borderColorChange="updateBorderColor" @signatureWidthChange="applySignatureWidth"
          @toggleSocialIconText="toggleSocialIconText" @socialIconColorChange="updateSocialIconColor"
          @plannedAbsenceColorChange="updatePlannedAbsenceColor"
          @plannedAbsenceTextColorChange="updatePlannedAbsenceTextColor" @toggleProfileImage="toggleProfileImage"
          @profileImageSizeChange="applyProfileImageSize" @profileImageShapeChange="applyProfileImageShape"
          @socialIconShapeChange="applySocialIconShape" @fontFamilyChange="applyFontFamily"
          @fontSizeChange="applyFontSize" @add-field="addAdditionalField" @remove-field="removeAdditionalField"
          @update-field-value="updateFieldValue" @template-selected="handleTemplateSelected" @toggle-logo="toggleLogo"
          @toggle-disclaimer="toggleDisclaimer" @logoSizeChange="applyLogoSize"
          @update-profile-image-href="updateProfileImageHref" @update-logo-image-href="updateLogoImageHref"
          @socialIconSizeChange="applySocialIconSize" @contactIconSizeChange="applyContactIconSize"
          @contactIconShapeChange="applyContactIconShape" @contactIconColorChange="applyContactIconColor"
          @show-modal="showModal = true" @update-link="updateLink" @update-banner="updateBanner"
          @update-plannedAbsence="updatePlannedAbsence" @update-meetingChat="updateMeetingChat"
          @meetingWidthChange="updateMeetingWidth" @update-disclaimer="updateDisclaimer"
          @addonsColorChange="updateAddonsColor" @meetingColorChange="updateMeetingColor"
          @chatColorChange="updateChatColor" @bannerWidthChange="updateBannerWidth" @update-feedback="updateFeedback"
          @toggle-icon-color="toggleIconColor" @toggle-contact-icon-color="toggleContactIconColor"
          @reset-contact-icons="resetContactIcons"></router-view>
      </section>
      <section v-if="!isLoading" :class="['preview', { isPreview: isPreview }]">
        <a href="https://www.gsignature.com" target="_blank" rel="noopener noreferrer">
          <img class="logo" :src="currentLogo" alt="logo" />
        </a>

        <BrandFetcher ref="brandFetcher" v-if="!isPreview && isMobile" :formData="formData"
          @update-form-data="updateFormData" />

        <div class="email-client">
          <div class="title-bar">
            <div class="buttons">
              <div class="close"></div>
              <div class="minimize"></div>
              <div class="maximize"></div>
            </div>
            <div class="title">
              {{ $t('mainPanel.emailPreview') }}
            </div>

            <BrandFetcher ref="brandFetcherDesktop" v-if="!isPreview && !isMobile" :formData="formData"
              @update-form-data="updateFormData" />

            <div v-if="showingToast" class="toast">{{ toastMessage }}</div>
          </div>
          <div class="toolbar">
            <div class="undo-button" :class="{ disabled: !canUndo }" @click="undo"
              @mouseover="showToast($t('mainPanel.undoLastAction'))" @mouseleave="hideToast">
              <i class="fas fa-arrow-left"></i>
            </div>
            <div class="redo-button" :class="{ disabled: !canRedo }" @click="redo"
              @mouseover="showToast($t('mainPanel.redoLastAction'))" @mouseleave="hideToast">
              <i class="fas fa-arrow-right"></i>
            </div>
            <div class="reset-settings" @click="confirmResetSettings"
              @mouseover="showToast($t('mainPanel.resetAllSettings'))" @mouseleave="hideToast">
              <i class="fas fa-undo-alt"></i>
            </div>
            <div class="dark-mode-toggle" @click="toggleDarkMode"
              @mouseover="showToast(isDarkMode ? $t('mainPanel.switchToLightMode') : $t('mainPanel.switchToDarkMode'))"
              @mouseleave="hideToast">
              <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
            </div>
            <div class="copy-link-toggle" @click="copyLink" @mouseover="showToast($t('brandFetcher.copyLinkTitle'))"
              @mouseleave="hideToast">
              <i class="fas fa-link"></i>
            </div>
            <div v-if="copySuccess" class="toast">
              {{ $t('brandFetcher.linkCopied') }}
            </div>
          </div>
          <div class="email-header">
            <div class="email-from">
              {{ $t('mainPanel.from') }}: gSignature Generator
            </div>
            <div class="email-to">
              {{ $t('mainPanel.to') }}:
              {{ previewEmail ||
                ($t('mainPanel.recipientPlaceholder') + '@gsignature.com')
              }}
            </div>
            <div class="email-subject">
              {{ $t('mainPanel.title') }}:
              {{
                formData.subject ||
                $t('mainPanel.emailSubjectPlaceholder')
              }}
            </div>
            <div class="email-date">
              {{ $t('mainPanel.date') }}:
              {{ formData.date || $t('mainPanel.defaultDatePlaceholder') }}
            </div>
          </div>
          <div class="email-body">
            <signature-preview ref="signaturePreview" v-if="hasFormData" :name="formData.name"
              :jobTitle="formData.jobTitle" :companyName="formData.companyName"
              :companyAddress="formData.companyAddress" :phoneNumber="formData.phoneNumber"
              :phoneLabel="formData.phoneLabel" :mobileNumber="formData.mobileNumber"
              :mobileLabel="formData.mobileLabel" :emailAddress="formData.emailAddress"
              :emailLabel="formData.emailLabel" :mainColor="formData.mainColor"
              :secondaryColor="formData.secondaryColor" :signatureWidth="formData.signatureWidth"
              :selectedIcons="formData.selectedIcons" :socialIconText="formData.socialIconText"
              :socialLinks="formData.socialLinks" :iconLinks="currentIconLinks" :profileImage="formData.profileImage"
              :profileImageHref="formData.profileImageHref" :descriptionBackground="formData.descriptionBackground"
              :borderColor="formData.borderColor" :showProfileImage="formData.showProfileImage"
              :profileImageSize="formData.profileImageSize" :profileImageShape="formData.profileImageShape"
              :socialIconShape="formData.socialIconShape" :fontFamily="formData.fontFamily"
              :fontSize="formData.fontSize" :additionalFields="formData.additionalFields"
              :template="formData.selectedTemplate" :customIcons="formData.customIcons" :logoImage="formData.logoImage"
              :logoImageHref="formData.logoImageHref" :showLogo="formData.showLogo"
              :disclaimerWidth="formData.disclaimerWidth" :logoSize="formData.logoSize"
              :socialIconColor="formData.socialIconColor" :plannedAbsenceColor="formData.plannedAbsenceColor"
              :plannedAbsenceTextColor="formData.plannedAbsenceTextColor" :socialIconSize="formData.socialIconSize"
              :contactIconShape="formData.contactIconShape" :contactIconColor="formData.contactIconColor"
              :contactIconSize="formData.contactIconSize" :link="formData.link" :banner="formData.banner"
              :disclaimer="formData.disclaimer" :plannedAbsence="formData.plannedAbsence"
              :meetingChat="formData.meetingChat" :meetingWidth="formData.meetingWidth"
              :addonsColor="formData.addonsColor" :meetingColor="formData.meetingColor" :chatColor="formData.chatColor"
              :bannerWidth="formData.bannerWidth" :feedback="formData.feedback" :isWhiteIcons="formData.isWhiteIcons"
              :isWhiteContactIcons="formData.isWhiteContactIcons" :selectedContactIcons="formData.selectedContactIcons"
              :contactIconLinksBlack="formData.contactIconLinksBlack"
              :contactIconLinksWhite="formData.contactIconLinksWhite"></signature-preview>
          </div>
        </div>
      </section>
    </main>

    <ModalSave :show="showSaveModalFlag" :formData="formData" :code="generatedCode" :originalFormData="originalFormData"
      @close="hideSaveModalFlag" @send-email="handleSendEmail" @update-form-data-for-export="updateFormDataForExport"
      @restore-original-form-data="restoreOriginalFormData" />
    <ModalAdd :show="showModal" @close="showModal = false" @add-field="handleAddField" />
    <ModalCode :show="showCodeModalFlag" :code="generatedCode" @close="showCodeModalFlag = false" />
    <ModalReset :show="showResetModal" @close="showResetModal = false" @confirm="handleConfirmReset" />
    <LanguageSelector v-if="!isMobile" :isDarkMode="isDarkMode" />
  </div>
</template>

<script>
import LanguageSelector from "../components/language/LanguageSelector.vue";
import SignaturePreview from "@/views/GeneratorPreview.vue";
import ModalAdd from "../components/modals/ModalAdd.vue";
import ModalCode from "../components/modals/ModalCode.vue";
import ModalReset from "../components/modals/ModalReset.vue";
import ModalSave from "../components/modals/ModalSave.vue";
import Loader from "../components/loader/LoaderView.vue";
import BrandFetcher from "../components/fetcher/BrandFetcher.vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { saveAs } from "file-saver";
import { templatesFormData } from "@/data/TemplatesFormData";
import { BACKEND_URL } from "@/environments";

export default {
  name: "MainPanel",
  components: {
    LanguageSelector,
    SignaturePreview,
    ModalAdd,
    ModalCode,
    ModalReset,
    ModalSave,
    Loader,
    BrandFetcher,
  },
  setup() {
    const router = useRouter();
    return { basePath: router.options.history.base };
  },
  data() {
    const domain = window.location.origin;
    const basePath = this.basePath.endsWith("/") ? this.basePath : `${this.basePath}/`;
    return {
      showingToast: false,
      toastMessage: "",
      toastTimeout: null,
      isMobile: window.innerWidth < 997,
      dynamicDescriptionBackgrounds: {},
      previewEmail: "",
      isLoading: false,
      entryTime: null,
      clickCount: 0,
      changeCount: 0,
      showHeader: true,
      showModal: false,
      showCodeModalFlag: false,
      showResetModal: false,
      showSaveModalFlag: false,
      isDarkMode: false,
      navOpen: false,
      isPreview: false,
      isSettings: false,
      isDev: false,
      generatedCode: "",
      copySuccess: false,
      formData: {
        name: "Maria Rodriguez",
        jobTitle: "Support Team",
        companyName: "Acme Inc.",
        companyAddress: "Suite 52 66425 Lang Stream, Jorgeshire, ME 81915-5433",
        phoneNumber: "12 345 6789",
        phoneLabel: "T:",
        mobileNumber: "(234) 121-7878",
        mobileLabel: "T:",
        emailAddress: "maria.rodriguez@acme.com",
        emailLabel: "E:",
        showProfileImage: true,
        showLogo: true,
        disclaimerWidth: false,
        plannedAbsenceColor: "#F6F6F6",
        plannedAbsenceTextColor: "#6B6B6B",
        link: { name: "", url: "" },
        banner: {
          url: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-13T17:37:33.710Z_247675d4-c819-4e3b-acd3-303011527a7e",
          href: "",
        },
        feedback: {
          question: "",
          likeIcon: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:02.337Z_18660755-126d-41eb-827e-0b6d751fb232",
          likeLink: `${domain}${basePath}positive`,
          dislikeIcon: "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-08-26T13:43:13.412Z_bd177bcd-2cea-4537-9ed9-e970eb8f3e79",
          dislikeLink: `${domain}${basePath}negative`,
        },
        plannedAbsence: {
          text: "I'll be out of the office on:",
          date: "November 15 - November 30, 2024",
        },
        disclaimer:
          "The content of this email is intended for the person or entity to which it is addressed only. This email may contain confidential information. If you are not the person to whom this message is addressed, be aware that any use, reproduction, or distribution of this message is strictly prohibited. If you received this in error, please contact the sender and immediately delete this email and any attachments.",
        profileImage:
          "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-23T13:32:16.789Z_cf3b3671-36bb-4356-96ee-8be1d33d1fb6",
        profileImageHref: "",
        logoImage:
          "https://storage.googleapis.com/prod_gsignature_user_images/generator.gs.com/media/images/2024-09-19T10:41:23.493Z_afe1353a-a95d-4b34-8d35-ef49fab4cf22",
        logoImageHref: "",
        additionalFields: [],
        customIcons: [],
        date: this.getFormattedDate(),
        ...templatesFormData.template1,
      },
      history: [],
      redoStack: [],
    };
  },
  computed: {
    hasFormData() {
      return (
        this.formData.name ||
        this.formData.jobTitle ||
        this.formData.companyName ||
        this.formData.companyAddress ||
        this.formData.phoneNumber ||
        this.formData.phoneLabel ||
        this.formData.mobileNumber ||
        this.formData.mobileLabel ||
        this.formData.emailAddress ||
        this.formData.emailLabel ||
        this.formData.additionalFields.length > 0
      );
    },
    isDevMode() {
      return this.$route.path.includes("/dev");
    },
    currentLogo() {
      return this.isDarkMode
        ? require("../assets/images/logo/logo_gsignature_light.svg")
        : require("../assets/images/logo/logo_gsignature_dark.svg");
    },
    currentIconLinks() {
      return this.formData.isWhiteIcons
        ? this.formData.iconLinksWhite
        : this.formData.iconLinksBlack;
    },
    canUndo() {
      return this.history.length > 0;
    },
    canRedo() {
      return this.redoStack.length > 0;
    },
  },
  methods: {
    copyLink() {
      const brandFetcherRef =
        this.$refs.brandFetcher || this.$refs.brandFetcherDesktop;
      let brandDomain = "";

      if (brandFetcherRef) {
        brandDomain = brandFetcherRef.brandDomain || "";
      }

      const trimmedDomain = brandDomain.trim();
      const link = trimmedDomain
        ? `https://generator.gsignature.com/?domain=${trimmedDomain}`
        : "https://generator.gsignature.com";

      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.hideToast();
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 2000);
        })
    },
    getFormattedDate() {
      const today = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      return today.toLocaleDateString("en-US", options);
    },
    showToast(message) {
      this.toastMessage = message;
      this.showingToast = true;
      if (this.toastTimeout) clearTimeout(this.toastTimeout);
      this.toastTimeout = setTimeout(() => this.hideToast(), 2000);
    },
    hideToast() {
      this.showingToast = false;
      this.toastMessage = "";
      if (this.toastTimeout) clearTimeout(this.toastTimeout);
    },
    convertRgbToHex(color) {
      if (!color.startsWith("rgb")) return color;
      const rgb = color.match(/\d+/g);
      if (!rgb) return color;
      const hex = rgb
        .map((num) => parseInt(num, 10).toString(16).padStart(2, "0"))
        .join("");
      return `#${hex}`;
    },
    processHtml(html) {
      let cleanedHtml = html.replace(/<!--\s*-->/g, "");
      cleanedHtml = cleanedHtml.replace(
        /rgb\((\d+), (\d+), (\d+)\)/g,
        (_, r, g, b) => this.convertRgbToHex(`rgb(${r}, ${g}, ${b})`)
      );
      return cleanedHtml;
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
      const topNav = document.querySelector(".top-nav");
      if (this.navOpen && topNav) {
        topNav.classList.remove("hidden");
        topNav.classList.add("visible");
        document.body.style.overflow = "hidden";
        document.querySelector(".editor").style.overflow = "hidden";
      } else if (topNav) {
        topNav.classList.remove("visible");
        topNav.classList.add("hidden");
        document.body.style.overflow = "";
        document.querySelector(".editor").style.overflow = "";
      }
    },
    handleNavClick() {
      if (this.isMobile) this.toggleNav();
    },
    handleResize() {
      this.isMobile = window.innerWidth < 997;
      const topNav = document.querySelector(".top-nav");
      if (this.isMobile && topNav) {
        this.navOpen = false;
        topNav.classList.add("hidden");
      }
    },
    navigateTo(path) {
      if (path) window.open(path, "_blank");
    },
    generatePreviewLink() {
      const currentPath = window.location.pathname;
      return currentPath.replace("/dev/", "/preview/");
    },
    generateEditLink() {
      const currentPath = window.location.pathname;
      return currentPath.replace("/dev/", "/settings/");
    },
    updateCurrentDate() {
      const currentDate = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      this.formData.date = currentDate.toLocaleDateString("en-US", options);
    },
    checkIfPreview() {
      const currentPath = this.$route.path;
      this.isPreview = currentPath.includes("/preview");
    },
    checkIfDev() {
      const currentPath = this.$route.path;
      this.isDev = currentPath.includes("/dev");
    },
    checkIfSettings() {
      const currentPath = this.$route.path;
      this.isSettings = currentPath.includes("/settings");
    },
    async loadSettingsFromId(id) {
      this.isLoading = true;
      try {
        const url = `${BACKEND_URL}/get-signature?id=${id}`;
        const response = await axios.get(url);
        if (response.status !== 200)
          return Promise.reject(new Error("Failed to load settings"));
        const data = response.data;
        if (data.error) {
          console.error("Error from server:", data.error);
        } else {
          this.formData = { ...this.formData, ...data.settings };
          this.previewEmail = data.email;
        }
      } catch (error) {
        console.error("Error loading settings:", error);
      } finally {
        this.isLoading = false;
      }
    },
    updateFormData(data) {
      this.saveToHistory();
      this.formData = { ...this.formData, ...data };
    },
    mergeTemplate(newTemplate, defaultData, currentData) {
      const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
      let merged = { ...currentData };
      Object.keys(newTemplate).forEach((key) => {
        if (
          newTemplate[key] &&
          typeof newTemplate[key] === "object" &&
          !Array.isArray(newTemplate[key]) &&
          currentData[key] &&
          typeof currentData[key] === "object" &&
          !Array.isArray(currentData[key]) &&
          defaultData[key] &&
          typeof defaultData[key] === "object" &&
          !Array.isArray(defaultData[key])
        ) {
          merged[key] = this.mergeTemplate(
            newTemplate[key],
            defaultData[key],
            currentData[key]
          );
          defaultData[key] = merged[key];
        } else {
          if (isEqual(currentData[key], defaultData[key])) {
            merged[key] = newTemplate[key];
            defaultData[key] = newTemplate[key];
          }
          if (!Object.prototype.hasOwnProperty.call(defaultData, key)) {
            merged[key] = newTemplate[key];
            defaultData[key] = newTemplate[key];
          }
        }
      });
      return merged;
    },
    handleTemplateSelected(templateName) {
      const newTemplate = templatesFormData[templateName];
      if (!newTemplate) return;

      const templatesAllowed = ["template2", "template3", "template7", "template8", "template13"];

      if (templatesAllowed.includes(this.formData.selectedTemplate)) {
        this.dynamicDescriptionBackgrounds[this.formData.selectedTemplate] = this.formData.descriptionBackground;
      }

      this.saveToHistory();
      this.formData = this.mergeTemplate(newTemplate, this.defaultFormData, this.formData);

      if (!templatesAllowed.includes(templateName)) {
        this.formData.descriptionBackground = newTemplate.descriptionBackground;
      } else {
        if (this.dynamicDescriptionBackgrounds[templateName]) {
          this.formData.descriptionBackground = this.dynamicDescriptionBackgrounds[templateName];
        }
      }
    },
    addCustomIcon(icon) {
      this.saveToHistory();
      this.formData.customIcons.push(icon);
    },
    updateProfileImage(imageUrl) {
      this.saveToHistory();
      this.formData.profileImage = imageUrl;
    },
    updateSelectedIcons(icons) {
      this.saveToHistory();
      this.formData.selectedIcons = icons;
    },
    toggleSocialIconText(socialIconText) {
      this.saveToHistory();
      this.formData.socialIconText = socialIconText;
    },
    updateSocialLinks(links) {
      this.saveToHistory();
      this.formData.socialLinks = links;
    },
    toggleIconColor() {
      this.saveToHistory();
      this.formData.isWhiteIcons = !this.formData.isWhiteIcons;
    },
    toggleContactIconColor() {
      this.saveToHistory();
      this.formData.isWhiteContactIcons = !this.formData.isWhiteContactIcons;
    },
    resetContactIcons() {
      this.saveToHistory();
      this.formData.selectedContactIcons = [];
    },

    updateTextColor(color) {
      this.saveToHistory();
      this.formData.mainColor = color;
    },
    updateMainColor(color) {
      this.saveToHistory();
      this.formData.mainColor = color;
    },
    updateSecondaryColor(color) {
      this.saveToHistory();
      this.formData.secondaryColor = color;
    },
    updateDescriptionBackground(color) {
      this.saveToHistory();
      this.formData.descriptionBackground = color;
    },
    updateBorderColor(color) {
      this.saveToHistory();
      this.formData.borderColor = color;
    },
    updateSocialIconColor(color) {
      this.saveToHistory();
      this.formData.socialIconColor = color;
    },
    updatePlannedAbsenceColor(color) {
      this.saveToHistory();
      this.formData.plannedAbsenceColor = color;
    },
    updatePlannedAbsenceTextColor(color) {
      this.saveToHistory();
      this.formData.plannedAbsenceTextColor = color;
    },
    applySignatureWidth(signatureWidth) {
      this.saveToHistory();
      this.formData.signatureWidth = signatureWidth;
    },
    toggleProfileImage(showProfileImage) {
      this.saveToHistory();
      this.formData.showProfileImage = showProfileImage;
    },
    applyProfileImageSize(profileImageSize) {
      this.saveToHistory();
      this.formData.profileImageSize = profileImageSize;
    },
    applyProfileImageShape(profileImageShape) {
      this.saveToHistory();
      this.formData.profileImageShape = profileImageShape;
    },
    applySocialIconShape(socialIconShape) {
      this.saveToHistory();
      this.formData.socialIconShape = socialIconShape;
    },
    applyContactIconShape(contactIconShape) {
      this.saveToHistory();
      this.formData.contactIconShape = contactIconShape;
    },
    applyContactIconSize(contactIconSize) {
      this.saveToHistory();
      this.formData.contactIconSize = contactIconSize;
    },
    applyContactIconColor(contactIconColor) {
      this.saveToHistory();
      this.formData.contactIconColor = contactIconColor;
    },
    updateLogoImage(imageUrl) {
      this.saveToHistory();
      this.formData.logoImage = imageUrl;
    },
    toggleLogo(showLogo) {
      this.saveToHistory();
      this.formData.showLogo = showLogo;
    },
    toggleDisclaimer(disclaimerWidth) {
      this.saveToHistory();
      this.formData.disclaimerWidth = disclaimerWidth;
    },
    applyLogoSize(logoSize) {
      this.saveToHistory();
      this.formData.logoSize = logoSize;
    },
    updateProfileImageHref(href) {
      this.saveToHistory();
      this.formData.profileImageHref = href;
    },
    updateLogoImageHref(href) {
      this.saveToHistory();
      this.formData.logoImageHref = href;
    },
    applySocialIconSize(size) {
      this.saveToHistory();
      this.formData.socialIconSize = size;
    },
    applyFontFamily(fontFamily) {
      this.saveToHistory();
      this.formData.fontFamily = fontFamily;
    },
    applyFontSize(fontSize) {
      this.saveToHistory();
      this.formData.fontSize = fontSize;
    },
    addAdditionalField(fields) {
      this.saveToHistory();
      if (fields) {
        this.formData.additionalFields = fields;
      } else {
        this.formData.additionalFields.push({
          label: `Text ${this.formData.additionalFields.length + 1}`,
          value: "",
          href: "",
          hrefType: "url",
        });
      }
    },
    removeAdditionalField(index) {
      this.saveToHistory();
      this.formData.additionalFields.splice(index, 1);
    },
    updateFieldValue(index, value) {
      this.saveToHistory();
      this.formData.additionalFields[index].value = value;
    },
    updateSelectedTemplate(templateName) {
      this.saveToHistory();
      this.formData.selectedTemplate = templateName;
    },
    updateAddonsColor(color) {
      this.saveToHistory();
      this.formData.addonsColor = color;
    },
    updateMeetingColor(color) {
      this.saveToHistory();
      this.formData.meetingColor = color;
    },
    updateChatColor(color) {
      this.saveToHistory();
      this.formData.chatColor = color;
    },
    updateBannerWidth(width) {
      this.saveToHistory();
      this.formData.bannerWidth = width;
    },
    updateLink(link) {
      this.saveToHistory();
      this.formData.link = link;
    },
    updateBanner(banner) {
      this.saveToHistory();
      this.formData.banner = banner;
    },
    updatePlannedAbsence(plannedAbsence) {
      this.saveToHistory();
      this.formData.plannedAbsence = plannedAbsence;
    },
    updateDisclaimer(disclaimer) {
      this.saveToHistory();
      this.formData.disclaimer = disclaimer;
    },
    updateFeedback(feedback) {
      this.saveToHistory();
      this.formData.feedback = feedback;
    },
    updateMeetingChat(meetingChat) {
      this.saveToHistory();
      this.formData.meetingChat = meetingChat;
    },
    updateMeetingWidth(width) {
      this.saveToHistory();
      this.formData.meetingWidth = width;
    },
    toggleDarkMode() {
      this.saveToHistory();
      this.isDarkMode = !this.isDarkMode;
    },
    saveSignature() {
      const template = this.$refs.signaturePreview.$el.innerHTML;
      const blob = new Blob([template], { type: "text/html;charset=utf-8" });
      saveAs(blob, "signature.html");
    },
    updateFormDataForExport() {
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      this.formData.name = this.formData.name ? "${userName}" : "";
      this.formData.jobTitle = this.formData.jobTitle ? "${jobTitle}" : "";
      this.formData.companyName = this.formData.companyName
        ? "${companyName}"
        : "";
      this.formData.companyAddress = this.formData.companyAddress
        ? "${companyAddress}"
        : "";
      this.formData.logoImage = this.formData.logoImage
        ? "${companyImageUrl}"
        : "";
      this.formData.phoneNumber = this.formData.phoneNumber
        ? "${labelPhoneNumber}"
        : "";
      this.formData.mobileNumber = this.formData.mobileNumber
        ? "${labelMobileNumber}"
        : "";
      this.formData.emailAddress = this.formData.emailAddress
        ? "${labelUserEmail}"
        : "";
      this.formData.profileImage = this.formData.profileImage
        ? "${userThumbnailUrl}"
        : "";
      this.formData.plannedAbsence.text = "";
      this.formData.plannedAbsence.date = "";
      this.formData.banner.url = "";
      this.formData.disclaimer = "";
      this.formData.phoneLabel = "";
      this.formData.mobileLabel = "";
      this.formData.emailLabel = "";
      this.$nextTick(() => {
        this.generateCode();
      });
    },
    restoreOriginalFormData() {
      if (!this.originalFormData) return;
      this.formData = JSON.parse(JSON.stringify(this.originalFormData));
      this.$nextTick(() => {
        if (this.$refs.signaturePreview) this.generateCode();
      });
    },
    generateCode() {
      const rawHtml = this.$refs.signaturePreview.$el.innerHTML;
      this.generatedCode = this.processHtml(rawHtml);
    },
    showSaveModal() {
      this.showSaveModalFlag = true;
    },
    hideSaveModalFlag() {
      this.restoreOriginalFormData();
      this.showSaveModalFlag = false;
    },
    showCodeModal() {
      const rawHtml = this.$refs.signaturePreview.$el.innerHTML;
      this.generatedCode = this.processHtml(rawHtml);
      this.showCodeModalFlag = true;
    },
    handleAddField(field) {
      this.saveToHistory();
      this.formData.additionalFields.push({
        title: field.title,
        label: field.label,
        value: field.value,
        icon: field.icon,
        href: field.href,
        hrefType: field.hrefType,
      });
    },
    confirmResetSettings() {
      this.showResetModal = true;
    },
    handleConfirmReset() {
      this.resetSettings();
      this.showResetModal = false;
    },
    resetSettings() {
      this.formData = JSON.parse(JSON.stringify(this.defaultFormData));
      this.isDarkMode = false;
      this.navOpen = false;
    },
    saveToHistory() {
      this.history.push(JSON.parse(JSON.stringify(this.formData)));
      this.redoStack = [];
    },
    undo() {
      if (this.canUndo) {
        const lastState = this.history.pop();
        this.redoStack.push(JSON.parse(JSON.stringify(this.formData)));
        this.formData = lastState;
      }
    },
    redo() {
      if (this.canRedo) {
        const nextState = this.redoStack.pop();
        this.history.push(JSON.parse(JSON.stringify(this.formData)));
        this.formData = nextState;
      }
    },
  },
  watch: {
    $route() {
      this.checkIfPreview();
      this.checkIfDev();
      this.checkIfSettings();
    },
    isDarkMode(newVal) {
      if (newVal) document.body.classList.add("dark-mode");
      else document.body.classList.remove("dark-mode");
    },
  },
  created() {
    this.entryTime = Date.now();
    this.defaultFormData = JSON.parse(JSON.stringify(this.formData));
    this.formData.date = this.getFormattedDate();
    this.updateCurrentDate();
    this.checkIfPreview();
    this.checkIfDev();
    this.checkIfSettings();
    const id = this.$route.params.id;
    if (id) this.loadSettingsFromId(id);
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
